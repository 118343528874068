import {
  DictionaryCandidateStage,
  DictionaryEditableItem,
  DictionaryItem,
  Entity,
} from '@app-shared/models';

export interface DirectoryCountry extends Entity {
  country?: string;
  countryIsoCodes?: string;
}

export interface DirectoryTag extends Entity {
  global: boolean;
  name: string;
}

export interface DirectorySkill extends DictionaryItem {
  skill: string;
  main_skill: boolean;
  is_soft: boolean;
}

export interface DirectorySkillSynonym extends DictionaryItem {
  skill: DirectorySkill;
}

type CustomFieldType = {
  [K in DirectoryCustomFieldTypes]: string[] | string | number | boolean;
};

export interface DirectoryCustomFieldResponse extends Partial<CustomFieldType> {
  id?: number;
  type: DirectoryFieldType;
  value: string | number | boolean | string[];
}

export interface DirectoryFieldType extends DictionaryItem {
  description?: string;
  type: string;
  showInPanel?: boolean;
  showInPublicWebsite?: boolean;
}

export interface AllDirectories {
  'bid-statuses'?: DictionaryCandidateStage[];
  tags?: DirectoryTag[];
  skills?: DirectorySkill[];
}

// eslint-disable-next-line no-shadow
export enum DirectoryTypesLabels {
  int = 'shared.models.directory.directory-types-labels.integer',
  bool = 'shared.models.directory.directory-types-labels.boolean',
  text = 'shared.models.directory.directory-types-labels.text',
  date = 'shared.models.directory.directory-types-labels.date',
  array = 'shared.models.directory.directory-types-labels.array',
  longtext = 'shared.models.directory.directory-types-labels.longtext',
}
// eslint-disable-next-line no-shadow
export enum DirectoryCustomFieldTypes {
  array = 'array',
  int = 'int',
  bool = 'bool',
  text = 'text',
  date = 'date',
  longtext = 'longtext',
}

export interface DirectoryReplaceDialogData {
  currentItemId: number;
  name: string;
  list: DictionaryEditableItem[];
}
