import {
  CalendarPayload,
  CalendarTarget,
  DateRange,
  FileType,
  HistoryEvent,
  MailTemplateTypes,
  NotificationChannel,
  ProfileContactRequestSource,
  ShortList,
  ShortListPayload,
  SkillWithNewWeight,
} from '@app-shared/models';
import { createAction, props, union } from '@ngrx/store';

const AddCommentFromDetailsAction = createAction(
  '[Profile Details Page] Adding comment to profile',
  props<{ comment: string; devId: string; bidId?: number }>(),
);
const AddToShortListFromDetailsAction = createAction(
  '[Profile Details Page] Add opened candidate to short list',
  props<{ shortList: Partial<ShortListPayload>; actionSource: 'details' }>(),
);
const BookProfileFromDetailsAction = createAction(
  '[Profile Details Page] Book opened candidate',
  props<{ profileId: string }>(),
);
const UnbookProfileFromDetailsAction = createAction(
  '[Profile Details Page] Unbook opened candidate',
  props<{ profileId: string }>(),
);
const ChangeVacancyAction = createAction(
  '[Profile Details Page] Change active vacancy in selector',
  props<{ id: number }>(),
);
const CloseRightPanelAction = createAction('[Profile Details Page] Close profile right panel');
const DeleteCommentFromDetailsAction = createAction(
  '[Profile Details Page] Delete comment from profile details page',
  props<{ id: number }>(),
);
const DeleteFileFromDetailsAction = createAction(
  '[Profile Details Files] Delete file from details page',
  props<{ devId: string; id: number; fileType: FileType }>(),
);
const DeleteHistoryEventAction = createAction(
  '[Profile Details | Timeline] Delete history record from profile',
  props<{ event: HistoryEvent }>(),
);
const DeleteProfileAction = createAction(
  '[Profile Details Page] Delete profile',
  props<{ id: string }>(),
);
const GetProfileHistoryFromDetailsOnInitAction = createAction(
  '[Profile Details History Page] Initial request profile history events on page open',
  props<{ query: DateRange }>(),
);
const GetProfileHistoryFromDetailsAction = createAction(
  '[Profile Details History Page] Request profile history events',
  props<{ query: DateRange }>(),
);
const GetProfileContactsAction = createAction(
  '[Profile Details Page] Get profile contacts from details page',
  props<{
    id: string;
    source: ProfileContactRequestSource;
    profileName: string;
    clickedContact: string;
  }>(),
);
const LinkSuggestionAction = createAction(
  '[Profile Details Page] Link suggested profile with existed profile',
  props<{ ids: string[] }>(),
);
const OpenRightPanelAction = createAction('[Profile Details Page] Open profile right panel');
const OpenShortListCalendarInDetailsActivitiesAction = createAction(
  '[Profile Details Page | Activities Tab] Open calendar page for setting interview event from profile details page',
  props<{ target: CalendarTarget; data: CalendarPayload; event?: string }>(),
);
const OpenShortListCalendarInDetailsSidepanelAction = createAction(
  '[Profile Details Page | Sidepanel] Open calendar page for setting interview event from sidepanel on profile details page',
  props<{ target: CalendarTarget; data: CalendarPayload }>(),
);
const RemoveFromShortListInDetailsActivitiesAction = createAction(
  '[Profile Details Page | Activities Tab] Remove profile from short list from profile details page',
  props<{ id: number }>(),
);
const RemoveFromShortListInDetailsSidepanelAction = createAction(
  '[Profile Details Page | Sidepanel] Remove profile from short list from sidepanel on profile details page',
  props<{ id: number }>(),
);
const ResendShortlistForSecurityCheckAction = createAction(
  '[Profile Details Page] Resend short list for security check',
  props<{ id: number }>(),
);
const SentNotificationsAction = createAction(
  '[Profile Details Page] Sent notification to opened candidate',
  props<{
    id: string;
    notificationType: NotificationChannel;
    templateType: MailTemplateTypes;
    shortList?: ShortList;
  }>(),
);
const UpdateShortListInDetailsActivitiesAction = createAction(
  '[Profile Details Page | Activities Tab] Update existed short list from profile details page',
  props<{ previousShortList: Partial<ShortList>; shortList: Partial<ShortList> }>(),
);
const UpdateSkillWeightAction = createAction(
  '[Profile Details Skills] Change skill weight',
  props<{
    skill: SkillWithNewWeight;
    source: 'profilePage' | 'profileSidepanel';
    skillType: 'skill' | 'soft_skill';
  }>(),
);
const UploadFileFromDetailsAction = createAction(
  '[Profile Details Files] Upload file',
  props<{ id: string | number; file: File; fileType: FileType }>(),
);

const actions = {
  AddCommentFromDetailsAction,
  AddToShortListFromDetailsAction,
  UnbookProfileFromDetailsAction,
  BookProfileFromDetailsAction,
  ChangeVacancyAction,
  CloseRightPanelAction,
  DeleteCommentFromDetailsAction,
  DeleteFileFromDetailsAction,
  DeleteHistoryEventAction,
  DeleteProfileAction,
  GetProfileContactsAction,
  GetProfileHistoryFromDetailsOnInitAction,
  GetProfileHistoryFromDetailsAction,
  LinkSuggestionAction,
  OpenRightPanelAction,
  OpenShortListCalendarInDetailsActivitiesAction,
  OpenShortListCalendarInDetailsSidepanelAction,
  RemoveFromShortListInDetailsActivitiesAction,
  RemoveFromShortListInDetailsSidepanelAction,
  ResendShortlistForSecurityCheckAction,
  SentNotificationsAction,
  UpdateShortListInDetailsActivitiesAction,
  UpdateSkillWeightAction,
  UploadFileFromDetailsAction,
};

const profileDetailsUserActions = union(actions);

export type ProfileDetailsUserActionsType = typeof profileDetailsUserActions;

export const ProfileDetailsUserActions = actions;
