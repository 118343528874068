import { ReportExternalSource } from '@app-shared/models';
import { createReducer, on } from '@ngrx/store';
import { mergeLeft, mergeRight } from 'ramda';
import { ReportsActions } from '../actions/reports.actions';

export const reportsFeatureKey = 'reports';

export interface ReportsState {
  isLoading: boolean;
  externalSources: ReportExternalSource[];
}

export const initialState: ReportsState = {
  isLoading: false,
  externalSources: null,
};

export const reducer = createReducer(
  initialState,
  on(ReportsActions.GetExternalSources, mergeLeft({ isLoading: true })),
  on(ReportsActions.GetExternalSourcesFailed, mergeLeft({ isLoading: false })),
  on(ReportsActions.GetExternalSourcesSuccess, (state, { sources }) =>
    mergeRight(state, { isLoading: false, externalSources: sources }),
  ),
);
