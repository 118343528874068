/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { ProfileDetailsSystemActions } from '@app-core/profile-details/actions/profile-details.system.actions';
import { ProfileDetailsUserActions } from '@app-core/profile-details/actions/profile-details.user.actions';
import { getFullDeveloperInfo as getFullDeveloperInfoFromDetailsPage } from '@app-core/profile-details/reducers/profile-details.reducer';
import { ProfileSidepanelSystemActions } from '@app-core/profile-side-panel/actions/profile-sidepanel.system.actions';
import { ProfileSidepanelUserActions } from '@app-core/profile-side-panel/actions/profile-sidepanel.user.actions';
import { getFullDeveloperInfo as getFullDeveloperInfoFromSidepanel } from '@app-core/profile-side-panel/reducers/profile-sidepanel.reducer';
import { getCurrentUser } from '@app-core/root/reducer/root.reducer';
import { ProfilesApiActions } from '@app-shared/actions/api-actions/profiles-api.actions';
import { InternalNotificationActions } from '@app-shared/actions/internal-notification.actions';
import { NotifyTrackersActions } from '@app-shared/actions/notify-trackers.actions';
import { UpdateVacancyDataAction } from '@app-shared/components/vacancy-selector/actions/vacancy-selector.system.actions';
import { formFileData } from '@app-shared/functions/profile-details-modifications/profile-details-modifications';
import {
  CommentResponse,
  DictionariesEnum,
  DictionaryShortList,
  FilePayload,
  FullProfile,
  HistoryParams,
  HistoryScope,
  ShortListCreateResponse,
  ShortListUpdateResponse,
  UnaryOperator,
} from '@app-shared/models';
import { State } from '@app-shared/reducers';
import { getShortListStatuses } from '@app-shared/reducers/dictionary/dictionary.reducer';
import { getSelectedVacancyDetails } from '@app-shared/reducers/vacancy/vacancy.reducer';
import { HistoryEventsService } from '@app-shared/services/history-events/history-events.service';
import { NewEntityService } from '@app-shared/services/new-entity/new-entity.service';
import { ProfileService } from '@app-shared/services/profile/profile.service';
import { ShortListService } from '@app-shared/services/short-list/short-list.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { anyPass, find, isEmpty, isNil, pipe, prop, propEq, propOr, split, test } from 'ramda';
import { of } from 'rxjs';
import {
  catchError,
  concatMap,
  delay,
  exhaustMap,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  AddCommentFailedAction,
  AddCommentSuccessfulAction,
  CreateShortListFromProfileFailedAction,
  CreateShortListFromProfileSuccessAction,
  DeleteCommentFailedAction,
  DeleteCommentSuccessfulAction,
  DeleteFileFailedAction,
  DeleteFileSuccessAction,
  GetProfileHistoryAction,
  GetProfileHistoryFailedAction,
  GetProfileHistorySuccessAction,
  ProfileModificationActions,
  RemoveFromShortListFailedAction,
  RemoveFromShortListSuccessAction,
  UpdateShortListFailedAction,
  UpdateShortListSuccessAction,
  UpdateSkillWeightFailedAction,
  UpdateSkillWeightSuccessAction,
  UpdateTagsAction,
  UpdateTagsFailedAction,
  UpdateTagsSuccessAction,
  UploadFileFailedAction,
  UploadFileSuccessAction,
} from './profile-modification.actions';

@Injectable()
export class ProfileModificationEffects {
  public refreshProfileDelay = 500;

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<State>,
    private readonly historyService: HistoryEventsService,
    private readonly profileService: ProfileService,
    private readonly newEntityService: NewEntityService,
    private readonly shortListService: ShortListService,
    private readonly translateService: TranslateService,
  ) {}

  public addCommentToProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProfileSidepanelUserActions.AddCommentFromSidepanelAction,
        ProfileDetailsUserActions.AddCommentFromDetailsAction,
      ),
      withLatestFrom(this.store$),
      map(([action, state]) => ({
        ...action,
        currentUser: getCurrentUser(state),
      })),
      map(({ devId, comment, bidId, currentUser }) => ({
        target: anyPass([isNil, isEmpty])(bidId) ? 'profile' : 'bid',
        id: bidId || devId,
        currentUser,
        comment,
      })),
      exhaustMap(({ target, comment, id, currentUser }) =>
        this.newEntityService.createComment(target, { id, comment }).pipe(
          mergeMap((item: CommentResponse) => {
            const [firstName, lastName] = split(' ', currentUser.name);

            const shouldRefreshTagsAction = test(/#\p{L}+/giu, comment)
              ? [
                  ProfilesApiActions.RefreshTagsDictionaryAction({
                    dictionaries: [DictionariesEnum.tags],
                  }),
                ]
              : [];

            const bidData =
              target === 'bid'
                ? {
                    bid: item.bid,
                  }
                : {};

            return [
              ...shouldRefreshTagsAction,
              AddCommentSuccessfulAction({
                comment: {
                  addedBy: {
                    firstName,
                    lastName,
                    id: item.addedBy.id,
                    image: prop('image', currentUser),
                  },
                  comment,
                  ...bidData,
                  createdAt: DateTime.local().toUTC().toISO(),
                  id: item.id,
                },
              }),
              NotifyTrackersActions.TrackAddCommentToProfileAction({
                category: 'Profile',
                action: 'add_comments',
                value: 1,
              }),
              InternalNotificationActions.SuccessNotificationAction({
                message: 'profile-common.effects.comment-added',
              }),
            ];
          }),
          catchError(() => of(AddCommentFailedAction())),
        ),
      ),
    ),
  );

  public addToShortList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProfileDetailsUserActions.AddToShortListFromDetailsAction,
        ProfileSidepanelUserActions.AddToShortListFromSidepanelAction,
      ),
      withLatestFrom(this.store$),
      map(([{ shortList, actionSource }, state]) => ({
        actionSource,
        shortList,
        selectedVacancy: getSelectedVacancyDetails(state),
        shortListStatuses: getShortListStatuses(state),
      })),
      map(({ actionSource, shortList, selectedVacancy, shortListStatuses }) => ({
        actionSource,
        shortList: { ...shortList, project: selectedVacancy },
        isLongList: pipe(
          find(propEq(shortList.status.id, 'id')) as UnaryOperator<
            DictionaryShortList[],
            DictionaryShortList
          >,
          propEq('long_list', 'code'),
        )(shortListStatuses),
        source: 'profile_page',
        shortListStatuses,
      })),
      switchMap(({ actionSource, shortList, source, isLongList, shortListStatuses }) =>
        this.shortListService.createShortList(shortList, source).pipe(
          mergeMap((newBid: ShortListCreateResponse) => {
            const listType = isLongList ? 'long' : 'short';
            const message = this.translateService.instant(
              `profile-common.effects.profile-added-${listType}`,
              { id: shortList.project.id, name: shortList.project.name },
            );
            return [
              CreateShortListFromProfileSuccessAction({
                actionSource,
                shortList: newBid,
                statuses: shortListStatuses,
              }),
              UpdateVacancyDataAction(),
              NotifyTrackersActions.TrackCreateShortListAction({
                category: 'ShortList',
                action: 'addTo',
                value: 7,
                label: '',
              }),
              InternalNotificationActions.SuccessNotificationAction({
                message,
              }),
            ];
          }),
          catchError(() => of(CreateShortListFromProfileFailedAction())),
        ),
      ),
    ),
  );

  public deleteCommentFromProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProfileSidepanelUserActions.DeleteCommentFromSidepanelAction,
        ProfileDetailsUserActions.DeleteCommentFromDetailsAction,
      ),
      exhaustMap(({ id }) =>
        this.profileService.deleteEntity('comment', id).pipe(
          map(() => DeleteCommentSuccessfulAction({ id })),
          catchError(() => of(DeleteCommentFailedAction())),
        ),
      ),
    ),
  );

  public deleteFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProfileDetailsUserActions.DeleteFileFromDetailsAction,
        ProfileSidepanelUserActions.DeleteFileFromSidepanelAction,
      ),
      exhaustMap(({ devId, id, fileType }) =>
        this.profileService.deleteEntity('file', id).pipe(
          map(() => DeleteFileSuccessAction({ devId, fileType, fileId: id })),
          catchError(() => of(DeleteFileFailedAction())),
        ),
      ),
    ),
  );

  public deleteHistoryEventFromProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileDetailsUserActions.DeleteHistoryEventAction),
      exhaustMap(({ event }) =>
        this.historyService.deleteEvent(event).pipe(
          mergeMap(() => {
            return [
              ProfileDetailsSystemActions.DeleteHistoryEventSuccessfulAction(),
              InternalNotificationActions.SuccessNotificationAction({
                message: 'profile-details.effects.history-event-deleted',
              }),
            ];
          }),
          catchError(() => of(ProfileDetailsSystemActions.DeleteHistoryEventFailedAction())),
        ),
      ),
    ),
  );

  public getProfileHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetProfileHistoryAction),
      map(({ query, id, actionSource }) => ({
        params: {
          ...query,
          scopeEntityId: id,
          scope: HistoryScope.PROFILE,
        },
        actionSource,
      })),
      exhaustMap(
        ({
          params,
          actionSource,
        }: {
          params: HistoryParams;
          actionSource: 'sidepanel' | 'details';
        }) =>
          this.historyService.getEntityHistory(params).pipe(
            map((events) => GetProfileHistorySuccessAction({ events, actionSource })),
            catchError(() => of(GetProfileHistoryFailedAction())),
          ),
      ),
    ),
  );

  public matchProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileModificationActions.MatchProfileDataAction),
      exhaustMap(({ isMatch, id, publicProfileId }) =>
        this.profileService.matchProfile(isMatch, id, publicProfileId).pipe(
          mergeMap((data) => [
            ProfileModificationActions.MatchProfileDataSuccessAction({ data, id }),
            InternalNotificationActions.SuccessNotificationAction({
              message: `shared.profile-modifications.effects.profile-${
                data.isMatch ? 'valid' : 'invalid'
              }`,
            }),
          ]),
          catchError(() => of(ProfileModificationActions.MatchProfileDataFailedAction())),
        ),
      ),
    ),
  );

  public mergeDuplicates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileModificationActions.MergeProfileDuplicatesAction),
      exhaustMap(({ primary, duplicates }) =>
        this.profileService.mergeProfileDuplicates({ primary, duplicates }).pipe(
          mergeMap(() => [
            ProfileModificationActions.MergeProfileDuplicatesSuccessAction({
              id: primary,
            }),
            InternalNotificationActions.SuccessNotificationAction({
              message: 'shared.profile-modifications.effects.profile-merged',
            }),
          ]),
          catchError(() => of(ProfileModificationActions.MergeProfileDuplicatesFailedAction())),
        ),
      ),
    ),
  );

  public refreshProfileDetailsWithDelay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateShortListFromProfileSuccessAction),
      withLatestFrom(this.store$),
      map(([{ actionSource }, state]) => ({
        developer:
          actionSource === 'sidepanel'
            ? getFullDeveloperInfoFromSidepanel(state)
            : getFullDeveloperInfoFromDetailsPage(state),
        actionSource,
      })),
      filter(
        ({ developer }: { developer: FullProfile; actionSource: 'sidepanel' | 'details' }) =>
          !propOr(false, 'isPrivate', developer),
      ),
      tap(({ actionSource }) => {
        const action =
          actionSource === 'sidepanel'
            ? ProfileSidepanelSystemActions.ToggleSidepanelLoadingStateAction({ isLoading: true })
            : ProfileDetailsSystemActions.ToggleProfileDetailsLoadingStateAction({
                isProfileLoading: true,
              });
        this.store$.dispatch(action);
      }),
      delay(this.refreshProfileDelay),
      map(({ actionSource }) =>
        actionSource === 'sidepanel'
          ? ProfileSidepanelSystemActions.RefreshSidepanelProfileAfterAddingToLongListAction()
          : ProfileDetailsSystemActions.RefreshProfileDetailsAfterAddingToLongListAction(),
      ),
    ),
  );

  public removeFromShortList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProfileDetailsUserActions.RemoveFromShortListInDetailsActivitiesAction,
        ProfileDetailsUserActions.RemoveFromShortListInDetailsSidepanelAction,
        ProfileSidepanelUserActions.RemoveFromShortListInSidepanelActivitiesAction,
        ProfileSidepanelUserActions.RemoveFromShortListInSidepanelVacancySelectorAction,
      ),
      switchMap(({ id }) =>
        this.shortListService.removeShortList(id).pipe(
          mergeMap(() => [
            RemoveFromShortListSuccessAction({ id }),
            UpdateVacancyDataAction(),
            InternalNotificationActions.SuccessNotificationAction({
              message: 'profile-common.effects.profile-updated',
            }),
          ]),
          catchError(() => of(RemoveFromShortListFailedAction())),
        ),
      ),
    ),
  );

  public resendShortListForSecurityCheck$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProfileDetailsUserActions.ResendShortlistForSecurityCheckAction,
        ProfileSidepanelUserActions.ResendShortlistForSecurityCheckAction,
      ),
      switchMap(({ id }) =>
        this.shortListService.resendShortListForSecurityCheck(id).pipe(
          mergeMap(() => [
            ProfileModificationActions.ResendShortListForSecurityCheckSuccessAction(),
            InternalNotificationActions.SuccessNotificationAction({
              message: 'profile-common.effects.profile-security-check-resend',
            }),
          ]),
          catchError(() =>
            of(ProfileModificationActions.ResendShortlistForSecurityCheckFailedAction()),
          ),
        ),
      ),
    ),
  );

  public updateShortList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProfileDetailsUserActions.UpdateShortListInDetailsActivitiesAction,
        ProfileSidepanelUserActions.UpdateShortListInSidepanelActivitiesAction,
        ProfileSidepanelUserActions.UpdateShortListInSidepanelVacancySelectorAction,
      ),
      switchMap(({ previousShortList, shortList }) =>
        this.shortListService.updateBid(shortList.id, shortList).pipe(
          mergeMap((updatedBid: ShortListUpdateResponse) => {
            return [
              UpdateShortListSuccessAction({
                shortList: updatedBid,
              }),
              UpdateVacancyDataAction(),
              InternalNotificationActions.SuccessNotificationAction({
                message: 'profile-common.effects.profile-updated',
              }),
            ];
          }),
          catchError(() => of(UpdateShortListFailedAction({ shortList: previousShortList }))),
        ),
      ),
    ),
  );

  public updateSkillWeight$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProfileSidepanelUserActions.UpdateSkillWeightFromSidepanelAction,
        ProfileDetailsUserActions.UpdateSkillWeightAction,
      ),
      concatMap(({ skill, source, skillType }) =>
        this.profileService.changeSkillWeight(skill).pipe(
          mergeMap(() => {
            const notificationAction = NotifyTrackersActions.TrackChangeSkillWeightAction({
              category: 'Profile',
              action: skill.isIncrease
                ? `increase_${skillType}_value`
                : `decrease_${skillType}_value`,
              value: 0.5,
              label: source,
            });
            const successApiAction = UpdateSkillWeightSuccessAction({
              skill,
              skillType,
            });

            return [notificationAction, successApiAction];
          }),
          catchError(() => of(UpdateSkillWeightFailedAction())),
        ),
      ),
    ),
  );

  public updateTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateTagsAction),
      exhaustMap(({ data, devId, currentTab }) =>
        this.profileService.updateProfile(devId, data, 'tags').pipe(
          mergeMap((profile: FullProfile) => [
            UpdateTagsSuccessAction({ data: profile.tags }),
            InternalNotificationActions.SuccessNotificationAction({
              message: 'profile-common.effects.tags-updated',
            }),
            NotifyTrackersActions.TrackUpdateProfileTagsAction({
              category: 'Profile',
              action: `edit_tags`,
              value: 1,
              label: currentTab,
            }),
          ]),
          catchError(() => of(UpdateTagsFailedAction())),
        ),
      ),
    ),
  );

  public uploadFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProfileSidepanelUserActions.UploadFileFromSidepanelAction,
        ProfileDetailsUserActions.UploadFileFromDetailsAction,
      ),
      map(({ id, file, fileType }) => {
        const formData = formFileData(file, '0', fileType, id);
        return { formData, id, fileType };
      }),
      mergeMap(({ formData, id, fileType }) =>
        this.newEntityService.uploadFile<FilePayload>(formData).pipe(
          map((file) => UploadFileSuccessAction({ file, id, fileType })),
          catchError(() => of(UploadFileFailedAction())),
        ),
      ),
    ),
  );
}
