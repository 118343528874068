import { ActivatedRouteSnapshot } from '@angular/router';
import {
  AccessToken,
  AllDictionaries,
  ComplicatedRequestObject,
  DictionariesEnum,
  DictionaryItem,
  Locales,
  ProfileExternalDataSources,
  ProfilesSearchParams,
  SearchParamValue,
  UnaryOperator,
} from '@app-shared/models';
import { DateTime } from 'luxon';
import { CountryISO } from 'ngx-intl-tel-input-gg';
import {
  anyPass,
  append,
  applySpec,
  both,
  complement,
  concat,
  dropRepeats,
  equals,
  find,
  flatten,
  head,
  ifElse,
  includes,
  is,
  isEmpty,
  isNil,
  join,
  map,
  mapObjIndexed,
  nth,
  of,
  omit,
  pathOr,
  pipe,
  pluck,
  prepend,
  prop,
  propEq,
  reject,
  replace,
  split,
  tail,
  test,
  unless,
  values,
  when,
} from 'ramda';

export const checkValueIsLink = (value: string) => {
  const urlMatcher = /^http/;
  return test(urlMatcher, value);
};

export function isNotNullOrUndefined<T>(input: null | undefined | T): input is T {
  return !isNil(input);
}

export const checkAccessTokenValidity = (token: string | null): boolean => {
  if (!token) {
    return false;
  }

  const parsedToken: AccessToken = pipe(
    split('.'),
    nth(1),
    replace(/-/g, '+'),
    replace(/_/g, '/'),
    // eslint-disable-next-line deprecation/deprecation
    atob,
    JSON.parse,
  )(token);
  const currentDate = DateTime.fromJSDate(new Date());

  const expireDate = DateTime.fromSeconds(parsedToken.exp - 60);
  return expireDate > currentDate;
};

export function normalizeSearchParams(route: ActivatedRouteSnapshot): ProfilesSearchParams {
  const returnNumberOrString = when(
    pipe(parseInt, both(is(Number), complement(equals(NaN)))),
    parseInt,
  );
  const alwaysArrays = [
    'city',
    'country',
    'skills',
    'skillExceptions',
    'tags',
    'tagExceptions',
    'keywords',
    'keywordExceptions',
  ];

  return pipe(
    pathOr({}, ['queryParams']),
    omit(['sortField', 'sortDirection', 'pageNumber', 'pageSize', 'externalSearchSource']),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    mapObjIndexed((val: string, key) => when(() => key === 'complicatedRequest', JSON.parse)(val)),
    mapObjIndexed(
      when(
        anyPass([is(Array), is(String)]),
        ifElse(is(Array), map(returnNumberOrString), returnNumberOrString),
      ),
    ),
    mapObjIndexed((val: number | string | (string | number)[], key: string) =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      when(() => includes(key, alwaysArrays), unless(is(Array), of(Array)))(val),
    ),
  )(route);
}

export const convertStringToNumeric = (val: string) =>
  test(/\d*/g, val) ? parseInt(val, 10) : val;

export function constructTitle(
  searchParams: ProfilesSearchParams,
  dictionaries: AllDictionaries,
): string {
  const convertComplicatedParams = (complicatedParams: ComplicatedRequestObject[]) =>
    map(
      pipe(
        mapObjIndexed(
          (value: string | string[] | number[], key: keyof ComplicatedRequestObject) => {
            const separator = test(/exceptions/gi, key) ? ' & ' : ' | ';

            switch (key) {
              case 'skills':
                return getSearchParamsNames(value as number[], 'skills', separator);
              case 'skillsExceptions':
                return getSearchParamsNames(value as number[], 'skills', separator, true);
              case 'tags':
                return getSearchParamsNames(value as number[], 'tags', separator);
              case 'tagsExceptions':
                return getSearchParamsNames(value as number[], 'tags', separator, true);
              case 'keywords':
                return pipe(
                  join(separator),
                  when(
                    () => value.length > 1,
                    pipe(split(''), prepend('('), append(')'), join('')),
                  ),
                )(value as string[]);
              case 'keywordsExceptions':
                return pipe(
                  map(concat('not ')),
                  join(separator),
                  when(
                    () => value.length > 1,
                    pipe(split(''), prepend('('), append(')'), join('')),
                  ),
                )(value as string[]);
              default:
                return '';
            }
          },
        ) as UnaryOperator<ComplicatedRequestObject, Record<string, string>>,
        values as UnaryOperator<Record<string, string>, string[]>,
      ) as UnaryOperator<ComplicatedRequestObject, string[]>,
      complicatedParams,
    );
  const getSearchParamsNames = (
    ids: number[],
    key: keyof typeof DictionariesEnum,
    separator: string,
    isException = false,
  ) =>
    pipe(
      prop(key),
      unless(
        anyPass([isNil, isEmpty]),
        pipe(
          (dictionary: DictionaryItem[]) =>
            map((id) => find(propEq(id, 'id'), dictionary), ids) as DictionaryItem[],
          pluck('name') as UnaryOperator<DictionaryItem[], string[]>,
          reject(isNil),
          when(() => isException, map(concat('not '))),
          join(separator),
          when(() => ids.length > 1, pipe(split(''), prepend('('), append(')'), join(''))),
        ),
      ),
    )(dictionaries) as string;

  return unless(
    isNil,
    pipe(
      mapObjIndexed(
        (value: SearchParamValue | ComplicatedRequestObject[], key: keyof ProfilesSearchParams) => {
          switch (key) {
            case 'specialities':
            case 'cities':
            case 'countries': {
              return getSearchParamsNames(flatten([value as number[]]), key, ' | ');
            }
            case 'specialitiesExceptions': {
              return getSearchParamsNames(
                flatten([value as number[]]),
                'specialities',
                ' & ',
                true,
              );
            }
            case 'complicatedRequest':
              return convertComplicatedParams(value as ComplicatedRequestObject[]);
            default:
              return [];
          }
        },
      ),
      values,
      flatten,
      reject(anyPass([isNil, isEmpty])),
      dropRepeats,
      join(' & '),
    ),
  )(searchParams) as string;
}

export const scrollToTopList = (cssSelector: string): void => {
  setTimeout(() => {
    const content = document.getElementsByClassName(cssSelector)[0];
    if (content) {
      content.scrollTo({ top: 0, behavior: 'smooth' });
    }
  });
};

export const splitName = (name: string, firstNameKey: string, lastNameKey: string) =>
  unless(
    isNil,
    pipe(
      split(' '),
      applySpec({
        [firstNameKey]: head as UnaryOperator<string[], string>,
        [lastNameKey]: pipe(tail, join(' ')),
      }),
    ),
  )(name) as Record<string, string>;

export const getPhoneData = (locale: Locales, phoneCode: string | null) => {
  let defaultCountry: CountryISO;
  let defaultPhoneCode: string;
  let examplePhone: string;
  switch (locale) {
    case 'en':
      defaultPhoneCode = '+1';
      defaultCountry = CountryISO.UnitedStates;
      break;
    case 'ru':
      defaultPhoneCode = '+7';
      defaultCountry = CountryISO.Russia;
      break;
    default:
      defaultPhoneCode = '+38';
      defaultCountry = CountryISO.Ukraine;
      break;
  }
  switch (phoneCode || defaultPhoneCode) {
    case '+7':
      examplePhone = '+79876543210';
      break;
    case '+1':
      examplePhone = '+13022461234';
      break;
    default:
      examplePhone = '+380981234567';
      break;
  }
  return { examplePhone, defaultCountry };
};
export const getProfileDataSourceName = (source: ProfileExternalDataSources) => {
  switch (source) {
    case 'github':
      return 'GitHub';
    case 'gitlab':
      return 'GitLab';
    case 'grc':
    case 'hh':
    case 'head_hunter':
      return 'Head Hunter';
    case 'linkedin':
      return 'LinkedIn';
    case 'rabota_ua':
      return 'rabota ua';
    case 'stackoverflow':
      return 'stack overflow';
    case 'work_ua':
      return 'work ua';
    default:
      return source;
  }
};
