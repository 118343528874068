import {
  ClientShort,
  DirectoryFieldType,
  DirectorySkillSynonym,
  DirectoryTag,
  Entity,
  Locales,
  MailAttachment,
  MailTemplate,
  SimpleRecruiter,
} from '@app-shared/models';

export enum DictionariesEnum {
  cities = 'cities',
  clients = 'clients',
  clientType = 'clientType',
  countries = 'countries',
  currencies = 'currencies',
  durations = 'durations',
  employments = 'employments',
  englishLevels = 'englishLevels',
  experiences = 'experiences',
  mainSkills = 'mainSkills',
  markets = 'markets',
  profileVacancyStatuses = 'profileVacancyStatuses',
  recruiters = 'recruiters',
  rejectReasons = 'rejectReasons',
  shortListStatuses = 'shortListStatuses',
  shortListSources = 'shortListSources',
  skills = 'skills',
  softSkills = 'softSkills',
  specialities = 'specialities',
  sources = 'sources',
  tags = 'tags',
  typeDate = 'typeDate',
  vacancyEmployments = 'vacancyEmployments',
  vacancyStatuses = 'vacancyStatuses',
  vacancyType = 'vacancyType',
  securityGroups = 'securityGroups',
  'short-list-boards' = 'short-list-boards',
  'short-list-sources' = 'short-list-sources',
  'bid-statuses' = 'bid-statuses',
  'mail-attachments' = 'mail-attachments',
  'mail-templates' = 'mail-templates',
  'notification-templates' = 'notification-templates',
  'client-fields' = 'client-fields',
  'profile-fields' = 'profile-fields',
  'project-fields' = 'project-fields',
  'bid-statuses-fields' = 'bid-statuses-fields',
  'recruiter-fields' = 'recruiter-fields',
  'address-list' = 'address-list',
}

export enum dictionaryDefaultBidStatuses {
  'AI' = 'shared.models.dictionary.bid-statuses.ai',
  'Applies' = 'shared.models.dictionary.bid-statuses.applies',
  'Hired' = 'shared.models.dictionary.bid-statuses.hired',
  'Interview Planned' = 'shared.models.dictionary.bid-statuses.interview',
  'Long List' = 'shared.models.dictionary.bid-statuses.long-list',
  'Not Accepted' = 'shared.models.dictionary.bid-statuses.not-accepted',
  'Processing' = 'shared.models.dictionary.bid-statuses.processing',
  'Sent to Review' = 'shared.models.dictionary.bid-statuses.sent',
}

export enum dictionaryDurations {
  "I don't know" = 'shared.models.dictionary.durations.i-dont-know',
  'Task (less than a month)' = 'shared.models.dictionary.durations.task',
  'Short term (from 1 to 3 months)' = 'shared.models.dictionary.durations.short',
  'Middle term (more than 3 months)' = 'shared.models.dictionary.durations.middle',
  'Long term (more than 6 months)' = 'shared.models.dictionary.durations.long',
}

export interface DictionaryItem {
  id: number;
  name: string;
}
export interface DictionaryCity extends Entity {
  city?: string;
}
export interface DictionaryCountry extends DictionaryItem {
  countryIsoCodes: string;
}
export type DictionaryCustomFieldTypes =
  | 'clientCustomFields'
  | 'profileCustomFields'
  | 'projectCustomFields'
  | 'recruiterCustomFields'
  | 'statusesCustomFields';

export interface DictionarySkill extends DictionaryItem {
  slug?: string;
  main?: boolean;
}
export type DictionaryShortListCodes =
  | 'chat_bot_passed'
  | 'custom'
  | 'sent_to_review'
  | 'interview_planned'
  | 'long_list'
  | 'applies'
  | 'not_accepted'
  | 'ai'
  | 'hired';

export type DictionaryEditableItem =
  | DictionaryCandidateStage
  | DictionaryDepartment
  | DictionaryItem
  | DictionaryRejectReason
  | DictionaryShortListBoard
  | DictionarySkill
  | DirectoryFieldType
  | DirectorySkillSynonym
  | DirectoryTag
  | MailAttachment
  | MailTemplate;

export interface DictionaryCandidateStage extends DictionaryItem {
  attachedFieldTypes?: DirectoryFieldType[];
  author?: never;
  code?: string;
  color?: string;
  portal?: never;
  position?: number;
}

export interface DictionaryDepartment {
  id: string;
  name: string;
  level: number;
  parentsChain: string | null;
  parentId: string;
  children: string[];
}

export interface DictionarySpeciality extends DictionaryItem {
  speciality: string;
  translations: { name: string; id: number; locale: Locales }[];
}

export interface DictionaryRejectReason extends DictionaryItem {
  description: string;
  shortlistCount: number;
}
export interface DictionaryShortListBoard extends DictionaryItem {
  projectsCount: number;
  columns?: DictionaryShortListBoardTemplate[];
  createdAt?: string;
}
export interface DictionaryShortListBoardTemplate {
  id: number;
  position: number;
  color: string;
  status: DictionaryItem;
}
export interface DictionaryShortList extends DictionaryItem {
  attachedFieldTypes?: DirectoryFieldType[];
  code: DictionaryShortListCodes;
  color: string;
  fieldTypes?: DirectoryFieldType[];
  position: number;
  type?: string;
}
export interface DictionaryShortListSource extends DictionaryItem {
  code: string;
}
type defaultDictionary = { [K in DictionariesEnum]?: DictionaryItem[] } & {
  clients?: ClientShort[];
};
export interface AllDictionaries extends defaultDictionary {
  departments?: DictionaryDepartment[];
  ['mail-attachments']?: MailAttachment[];
  ['mail-templates']?: MailTemplate[];
  recruiters?: SimpleRecruiter[];
  shortListBoards?: DictionaryShortListBoard[];
  shortListStatuses?: DictionaryShortList[];
  skills?: DictionarySkill[];
}

export interface NotMappedDictionaryItem {
  _source: DictionaryItem;
}
export type NotMappedDictionary = {
  [K in DictionariesEnum]?: NotMappedDictionaryItem[];
};

export interface BreezyCompany {
  id: string;
  name: string;
  createdAt: string;
}
