import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { DateTime } from 'luxon';
import { DirectoryCustomFieldResponse, DirectoryFieldType } from './directory.model';
import { Entity } from './entities.model';
import { Locales } from './metadata.model';
import { MailTemplateTypes } from './notification.model';
import { SimpleRecruiter } from './profile.model';

export interface BidForm {
  comment: FormControl<string | null>;
  cvSentDate: FormControl<Date | null>;
  fieldValues: FormControl<DirectoryCustomFieldResponse[]>;
  hiredAt: FormControl<Date | null>;
  reasonRejectId: FormControl<number | null>;
  rejectedDate: FormControl<Date | null>;
  source_id: FormControl<number | null>;
  status: FormControl<number>;
  interviewAt: FormControl<Date | null>;
  salary: FormControl<number | null>;
}

export interface DirectoryBidStatusForm {
  name: FormControl<string>;
  color: FormControl<string>;
  fieldTypes: FormControl<DirectoryFieldType[]>;
}

export interface DirectoryFieldTypeForm {
  name: FormControl<string>;
  description: FormControl<string | null>;
  type: FormControl<string>;
  showInPanel: FormControl<boolean>;
}

export interface DirectoryMailAttachmentForm {
  name: FormControl<string>;
  description: FormControl<string | null>;
  url: FormControl<string>;
}

export interface DirectoryMailTemplateForm {
  attachments: FormControl<number[]>;
  body: FormControl<string>;
  bodySecondary: FormControl<string | null>;
  convertToPdf: FormControl<boolean>;
  filename: FormControl<string | null>;
  name: FormControl<string>;
  subject: FormControl<string | null>;
  type: FormControl<MailTemplateTypes>;
}

export interface DirectoryRejectReasonForm {
  name: FormControl<string>;
  description: FormControl<string>;
}

export interface DirectorySkillForm {
  name: FormControl<string>;
  mainSkill: FormControl<boolean>;
  isSoft: FormControl<boolean>;
}

export interface DirectorySkillSynonymsForm {
  name: FormControl<string>;
  skill: FormControl<number>;
}

export interface DirectorySpecialityForm {
  specialityLocale: FormControl<Locales>;
  en: FormControl<string>;
  ru: FormControl<string | null>;
  uk: FormControl<string | null>;
}

export interface DirectoryTagForm {
  name: FormControl<string>;
}

export interface PasswordManagementForm {
  password: FormControl<string>;
  confirmPassword: FormControl<string>;
}

export interface NewClientForm {
  city: FormControl<number | null>;
  clientCompanyName: FormControl<string | null>;
  clientType: FormControl<number | null>;
  country: FormControl<number | null>;
  createUser: FormControl<boolean>;
  customFields: FormControl<DirectoryCustomFieldResponse[]>;
  email: FormControl<string | null>;
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  skype: FormControl<string | null>;
  telephone: FormControl<string | null>;
}

export interface NewRecruiterForm {
  customFields: FormControl<DirectoryCustomFieldResponse[]>;
  email: FormControl<string>;
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  skype: FormControl<string | null>;
  telephone: FormControl<string | null>;
  recruiterDepartmentAccess: FormControl<string[]>;
  subordinates: FormControl<number[]>;
  // Recruiter accesses
  accessToCandidates: FormControl<boolean>;
  accessToOthers: FormControl<boolean>;
  accessToVacancies: FormControl<boolean>;
  clientsPage: FormControl<boolean>;
  dashboardPage: FormControl<boolean>;
  deleteComments: FormControl<boolean>;
  deleteVacancy: FormControl<boolean>;
  editVacancyCloseDate: FormControl<boolean>;
  editVacancyInformation: FormControl<boolean>;
  searchConsolePage: FormControl<boolean>;
  showCandidateStatusCustomFields: FormControl<boolean>;
  showClientsContactsDetails: FormControl<boolean>;
  showClientsCustomFields: FormControl<boolean>;
  showProfileContactDetails: FormControl<boolean>;
  showProfileCustomFields: FormControl<boolean>;
  showProfileSecondName: FormControl<boolean>;
  showProfileSocialLinks: FormControl<boolean>;
  showSalary: FormControl<boolean>;
  showVacancyCustomFields: FormControl<boolean>;
  teammatesPage: FormControl<boolean>;
  vacancyAiTab: FormControl<boolean>;
  vacancyNotificationsTab: FormControl<boolean>;
  vacancyStaticTab: FormControl<boolean>;
  viewPortalClients: FormControl<boolean>;
  viewPortalProfiles: FormControl<boolean>;
  viewPortalProjects: FormControl<boolean>;
  viewProfilesComments: FormControl<boolean>;
  viewProfilesFiles: FormControl<boolean>;
  viewProfilesNotifications: FormControl<boolean>;
  viewProfilesShortlists: FormControl<boolean>;
  viewVacancyComments: FormControl<boolean>;
}

interface ProfileBaseContactForm {
  main: FormControl<boolean>;
}
export interface EmailContactForm extends ProfileBaseContactForm {
  email: FormControl<string>;
}
export interface SkypeContactForm extends ProfileBaseContactForm {
  skype: FormControl<string>;
}
export interface PhoneContactForm extends ProfileBaseContactForm {
  phone: FormControl<string>;
}
export interface TelegramContactForm extends ProfileBaseContactForm {
  telegram: FormControl<string>;
}
export type NewProfileContactForm =
  | EmailContactForm
  | SkypeContactForm
  | PhoneContactForm
  | TelegramContactForm;

export interface NewProfileForm {
  birthday: FormControl<string | null>;
  city: FormControl<number | null>;
  country: FormControl<number | null>;
  currency: FormControl<string | null>;
  emails: FormArray<FormGroup<EmailContactForm>>;
  english: FormControl<number | null>;
  fieldValues: FormControl<DirectoryCustomFieldResponse[]>;
  firstName: FormControl<string>;
  image: FormControl<string | null>;
  insideInformation: FormControl<string | null>;
  lastName: FormControl<string | null>;
  level: FormControl<number | null>;
  mainSkill: FormControl<number | null>;
  middleName: FormControl<string | null>;
  phones: FormArray<FormGroup<PhoneContactForm>>;
  profileSummary: FormControl<string | null>;
  rate: FormControl<number | null>;
  rateCard: FormControl<number | null>;
  salaryComment: FormControl<string | null>;
  skills: FormControl<number[]>;
  skypes: FormArray<FormGroup<SkypeContactForm>>;
  socialLinks: FormArray<FormControl<string>>;
  softSkills: FormControl<number[]>;
  sourceId: FormControl<number | null>;
  speciality: FormControl<number | null>;
  telegrams: FormArray<FormGroup<TelegramContactForm>>;
  vacancy: FormControl<number | null>;
}

export interface NewVacancyDialogForm {
  assignedRecruiter: FormControl<number>;
  board: FormControl<number>;
  candidatesQuantity: FormControl<number>;
  city: FormControl<number | null>;
  clients: FormControl<number[]>;
  country: FormControl<number | null>;
  createdAt: FormControl<string>;
  currency: FormControl<string | null>;
  customFields: FormControl<DirectoryCustomFieldResponse[]>;
  deadlineDate: FormControl<string | null>;
  description: FormControl<string | null>;
  department: FormControl<string | null>;
  durationTime: FormControl<number | null>;
  firstHireAt: FormControl<string | null>;
  firstCvSentAt: FormControl<string | null>;
  firstInterviewAt: FormControl<string | null>;
  insideInformation: FormControl<string | null>;
  mainSkill: FormControl<number | null>;
  postToListing: FormControl<boolean>;
  rate: FormControl<number | null>;
  salary: FormControl<string | null>;
  salaryComment: FormControl<string | null>;
  shareTo: FormControl<SimpleRecruiter[]>;
  skills: FormControl<number[]>;
  speciality: FormControl<number | null>;
  startDate: FormControl<string | null>;
  status: FormControl<number | null>;
  successClosedDate: FormControl<string | null>;
  title: FormControl<string>;
  typeVacancy: FormArray<FormControl<boolean>>;
  unsuccessClosedDate: FormControl<string | null>;
}

export interface RecruiterSimpleForm {
  email: FormControl<string>;
  fieldValues: FormControl<DirectoryCustomFieldResponse[]>;
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  profileSummary: FormControl<string | null>;
  recruiterDepartment: FormControl<string[]>;
  skype: FormControl<string | null>;
  subordinates: FormControl<Entity[]>;
  telephone: FormControl<string | null>;
  webSite: FormControl<string | null>;
}

export interface RegistrationForm {
  agreement: FormControl<boolean>;
  password: FormControl<string>;
  skype: FormControl<string | null>;
  email: FormControl<string>;
  fullName: FormControl<string>;
}

export interface ShortListDialogForm extends ApproveOfferDialogForm {
  recruiterComment: FormControl<string>;
  fieldValues: FormControl<DirectoryCustomFieldResponse[]>;
  emails: FormControl<string[]>;
  cvLink: FormControl<string[]>;
  interviewAt: FormControl<Date>;
  reasonRejectId: FormControl<number>;
  department: FormControl<string>;
}
interface ApproveOfferDialogForm {
  budgetExcess: FormControl<boolean>;
  budgetCurrency: FormControl<string>;
  candidateOfferPosition: FormControl<string>;
  candidateOfferSalary: FormControl<string>;
  formingOffer: FormControl<boolean>;
  isFixedRate: FormControl<boolean>;
  motivationSystem: FormControl<string>;
  offerRecruiterComment: FormControl<string>;
  positionChanged: FormControl<boolean>;
  workStartingAt: FormControl<Date>;
}
export interface ShortListFormValue {
  recruiterComment?: string;
  fieldValues?: DirectoryCustomFieldResponse[];
  emails?: string[];
  cvLink?: string[];
  interviewAt?: Date;
  reasonRejectId?: number;
  department?: string;

  budgetExcess?: boolean;
  budgetCurrency?: string;
  candidateOfferPosition?: string;
  candidateOfferSalary?: string;
  formingOffer?: boolean;
  isFixedRate?: boolean;
  motivationSystem?: string;
  offerRecruiterComment?: string;
  positionChanged?: boolean;
  workStartingAt?: Date;
}

export interface SpaceConfigurationForm {
  name: FormControl<string>;
  mail_url: FormControl<string>;
  download_visited_profiles_to_portal: FormControl<boolean>;
  gdpr_should_sent_notification: FormControl<boolean>;
  allow_apply_from_public_website: FormControl<boolean>;
  days_to_deadline: FormControl<number>;
  search_duplicates_in_console: FormControl<boolean>;
  search_duplicates_in_details: FormControl<boolean>;
  search_duplicates_in_a_t_s: FormControl<boolean>;
}

export type VacanciesListFiltersForm = {
  clients: FormControl<number[]>;
  endDate: FormControl<DateTime | string>;
  keywords: FormControl<string>;
  recruiters: FormControl<number[]>;
  startDate: FormControl<DateTime | string>;
  typeDate: FormControl<string>;
  vacancyStatuses: FormControl<number[]>;
  vacancyType: FormControl<string>;
} & {
  [key in KernelVacanciesFilters]?: FormControl<string | string[]>;
};
export type VacanciesListFilters = {
  clients: number[];
  endDate: DateTime | string;
  keywords: string;
  recruiters: number[];
  startDate: DateTime | string;
  typeDate: string;
  vacancyStatuses: number[];
  vacancyType: string;
} & {
  [key in KernelVacanciesFilters]?: string;
};
export type VacancySidePanelEditForm = {
  assignedId: FormControl<number>;
  clients: FormControl<number[]>;
  status: FormControl<number>;
};

export type KernelVacanciesFilters = 'address' | 'departmentId' | 'managers';
export const kernelFiltersNames: KernelVacanciesFilters[] = ['address', 'departmentId', 'managers'];
