import { createAction, props, union } from '@ngrx/store';
import { ReportExternalSource } from '@app-shared/models';

const GetExternalSources = createAction('[Reports] Request external sources for spaces');
const GetExternalSourcesSuccess = createAction(
  '[Reports] External sources successfully received',
  props<{ sources: ReportExternalSource[] }>(),
);
const GetExternalSourcesFailed = createAction(
  '[Reports] Error happened during requesting external sources',
);

const actions = {
  GetExternalSources,
  GetExternalSourcesFailed,
  GetExternalSourcesSuccess,
};

const reportsActions = union(actions);

export type ReportsActionsType = typeof reportsActions;

export const ReportsActions = actions;
