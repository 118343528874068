import { DictionaryItem, Locales, ProfileExternalDataSources } from './';

export type Tag = Entity;

export interface Comment extends Entity {
  addedBy: Entity & {
    firstName?: string;
    lastName?: string;
    image?: string;
    imageThumbnail?: string;
  };
  bid?: {
    id: number;
    status: DictionaryItem & { color: string; code: string };
    project: DictionaryItem;
  };
  comment: string;
  createdAt: string | number;
  source?: DictionaryItem | Array<unknown>;
}
export interface CommentPayload {
  id: string | number;
  comment: string;
  source?: string;
}

export interface CommentResponse extends Entity {
  addedBy: Entity;
  bid?: {
    id: number;
    status: DictionaryItem & { color: string; code: string };
    project: DictionaryItem;
  };
  comment: string;
  project?: Entity;
  source?: {
    code: string;
  };
  profile?: {
    id: string;
  };
}

export type CommentTargets = 'bid' | 'project' | 'profileProject' | 'talent';
export interface Contacts {
  emails: Contact[];
  facebook?: string[];
  github?: string[];
  linkedin?: string[];
  other?: string[];
  phones: Contact[];
  rabota_ua?: string[];
  skypes: Contact[];
  stackoverflow?: string[];
  twitter?: string[];
  work_ua?: string[];
}
export interface Contact {
  email?: string;
  main?: boolean;
  phone?: string;
  skype?: string;
  telegram?: string;
  id?: number;
  url?: string;
}
export interface CV extends Entity {
  createdAt: string;
  filePath?: string;
  fileName?: string;
  path?: string;
  type?: string;
}
export type FileType = 'cv' | 'voice' | 'other';
export interface Entity {
  id: number;
}
export interface Locale extends Entity {
  code: Locales;
  language: string;
}
export interface SimpleProject {
  company: string;
  date: string;
  description?: string;
  experience: string;
  location?: string;
  name?: string;
  position: string;
  summary?: string;
  source: ProfileExternalDataSources;
  title?: string;
}
export interface ProfileProject {
  company?: string;
  date?: string;
  description?: string;
  experience?: string;
  title?: string;
  source?: string;
}
export interface Skill extends Entity {
  date?: string;
  isHighlighted?: boolean;
  main?: boolean;
  name?: string; // Only for vacancy profiles models
  profileSkillId?: number;
  slug?: string;
  soft?: boolean;
  softSkills?: boolean;
  weight?: number;
  sources?: ProfileExternalDataSources[];
}
export interface SkillWithNewWeight extends Entity {
  profileId: string;
  profileSkillId: number;
  weight?: number;
  isIncrease: boolean;
}
export interface UserOptions {
  // Recruiter access
  clientsPage: boolean;
  dashboardPage: boolean;
  deleteComments: boolean;
  deleteVacancy: boolean;
  editVacancyCloseDate: boolean;
  editVacancyInformation: boolean;
  searchConsolePage: boolean;
  showCandidateStatusCustomFields: boolean;
  showClientsContactsDetails: boolean;
  showClientsCustomFields: boolean;
  showProfileContactDetails: boolean;
  showProfileCustomFields: boolean;
  showProfileSecondName: boolean;
  showProfileSocialLinks: boolean;
  showSalary: boolean;
  showSocialLinks: boolean;
  showVacancyCustomFields: boolean;
  teammatesPage: boolean;
  vacancyNotificationsTab: boolean;
  vacancyStaticTab: boolean;
  viewPortalClients: boolean;
  viewPortalProfiles: boolean;
  viewPortalProjects: boolean;
  viewProfilesComments: boolean;
  viewProfilesFiles: boolean;
  viewProfilesNotifications: boolean;
  viewProfilesShortlists: boolean;
  viewVacancyComments: boolean;
  downloadVisitedProfilesToPortal: boolean;
  searchBySecurityId: boolean;
}
export interface ExportEntitiesPayload {
  emails: string[];
  exportPreview: boolean;
  portalMailTemplateId: number;
  mailTemplateParams: { recruiter_first_name: string; export_type: string; first_name: string };
}
