import { DashboardData, DashboardPortalStatistic, DashboardVideos } from '@app-shared/models';

import statistic from './data/dashboard-portal-usage-statistic.json';
import data from './data/dashboard.json';

export const mockedDashboard: DashboardData = data;

export const mockedDashboardVideos: DashboardVideos = {
  isVisible: true,
  list: [
    {
      image:
        'https://s3-eu-central-1.amazonaws.com/talentsearch-db-files-stage/2023/02/24/296_preview.jpg',
      url: 'https://www.youtube.com/embed/LSxZpVCyHTw',
      title: { en: 'Title', ru: 'Title', uk: 'Title' },
    },
    {
      image:
        'https://s3-eu-central-1.amazonaws.com/talentsearch-db-files-stage/2023/02/24/296_preview.jpg',
      url: 'https://www.youtube.com/embed/tW8Srf75kok',
      title: { en: 'Title', ru: 'Title', uk: 'Title' },
    },
    {
      image:
        'https://s3-eu-central-1.amazonaws.com/talentsearch-db-files-stage/2023/02/24/296_preview.jpg',
      url: 'https://www.youtube.com/embed/iTkZapjEVBE',
      title: { en: 'Title', ru: 'Title', uk: 'Title' },
    },
    {
      image:
        'https://s3-eu-central-1.amazonaws.com/talentsearch-db-files-stage/2023/02/24/296_preview.jpg',
      url: 'https://www.youtube.com/embed/qL43kjDOKEw',
      title: { en: 'Title', ru: 'Title', uk: 'Title' },
    },
    {
      image:
        'https://s3-eu-central-1.amazonaws.com/talentsearch-db-files-stage/2023/02/24/296_preview.jpg',
      url: 'https://www.youtube.com/embed/fK4pTtcMCIM',
      title: { en: 'Title', ru: 'Title', uk: 'Title' },
    },
    {
      image:
        'https://s3-eu-central-1.amazonaws.com/talentsearch-db-files-stage/2023/02/24/296_preview.jpg',
      url: 'https://www.youtube.com/embed/9aGnGOIeWRQ',
      title: { en: 'Title', ru: 'Title', uk: 'Title' },
    },
    {
      image:
        'https://s3-eu-central-1.amazonaws.com/talentsearch-db-files-stage/2023/02/24/296_preview.jpg',
      url: 'https://www.youtube.com/embed/-pmXaFFwliU',
      title: { en: 'Title', ru: 'Title', uk: 'Title' },
    },
    {
      image:
        'https://s3-eu-central-1.amazonaws.com/talentsearch-db-files-stage/2023/02/24/296_preview.jpg',
      url: 'https://www.youtube.com/embed/C-vHv_WC564',
      title: { en: 'Title', ru: 'Title', uk: 'Title' },
    },
    {
      image:
        'https://s3-eu-central-1.amazonaws.com/talentsearch-db-files-stage/2023/02/24/296_preview.jpg',
      url: 'https://www.youtube.com/embed/G6JjL3snWZY',
      title: { en: 'Title', ru: 'Title', uk: 'Title' },
    },
  ],
};
export const mockedPortalUsageStatistic = statistic as unknown as DashboardPortalStatistic;
