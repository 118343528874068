import {
  ClientShort,
  DictionaryCandidateStage,
  DictionaryDepartment,
  DictionaryItem,
  DictionaryShortListBoard,
  DictionarySkill,
  SimpleRecruiter,
} from '@app-shared/models';
import cities from './dictionaries/cities.json';
import clients from './dictionaries/clients.json';
import countries from './dictionaries/countries.json';
import departments from './dictionaries/departments.json';
import recruiters from './dictionaries/recruiters.json';
import skills from './dictionaries/skills.json';
import specialities from './dictionaries/specialities.json';
import tags from './dictionaries/tags.json';

export const mockedSkills: DictionarySkill[] = skills;
export const mockedTypeDate = [
  {
    id: 1,
    name: 'Project created',
  },
  {
    id: 2,
    name: 'Sent to Reviewer',
  },
  {
    id: 3,
    name: 'Interview planed',
  },
  {
    id: 4,
    name: 'Waiting for a feedback',
  },
  {
    id: 5,
    name: 'Done',
  },
];
export const mockedCities = cities;
export const mockedClients: ClientShort[] = clients;
export const mockedCountries = countries;
export const mockedDepartments: DictionaryDepartment[] = departments;
export const mockedDurations = [
  {
    id: 0,
    name: "I don't know",
  },
  {
    id: 1,
    name: 'Task (less than a month)',
  },
  {
    id: 2,
    name: 'Short term (from 1 to 3 months)',
  },
  {
    id: 3,
    name: 'Middle term (more than 3 months)',
  },
  {
    id: 4,
    name: 'Long term (more than 6 months)',
  },
];
export const mockedEmployments = [
  {
    id: 1,
    name: 'Remote job Full time',
  },
  {
    id: 2,
    name: 'Office job Full time',
  },
  {
    id: 3,
    name: 'Part time remote',
  },
  {
    id: 4,
    name: 'International Relocation',
  },
  {
    id: 5,
    name: 'Full cycle projects',
  },
  {
    id: 6,
    name: 'Work for equity (work in startups)',
  },
  {
    id: 7,
    name: 'Intern (work for experience)',
  },
  {
    id: 8,
    name: 'Volunteer (social projects)',
  },
];
export const mockedExperiences = [
  {
    full_name: 'Intern',
    id: 5,
    name: 'Intern',
  },
  {
    full_name: 'Junior (xp less than 1 year) $',
    id: 1,
    name: 'Junior',
  },
  {
    full_name: 'Middle (1-2 year xp) $$',
    id: 2,
    name: 'Middle',
  },
  {
    full_name: 'Senior (more than 3 year xp) $$$',
    id: 3,
    name: 'Senior',
  },
  {
    full_name: 'TechLead',
    id: 4,
    name: 'TechLead',
  },
];
export const mockedPlatforms = [
  {
    id: 1,
    name: 'Mobile',
  },
  {
    id: 2,
    name: 'Web',
  },
  {
    id: 3,
    name: 'Desktop',
  },
  {
    id: 4,
    name: 'Game',
  },
];
export const mockedRecruiters: SimpleRecruiter[] = recruiters;
export const mockedRejectReasons = [
  {
    id: 80167,
    name: 'Project is finished',
    description: 'We regret to inform you that the client has selected another candidate.',
    createdAt: '2021-10-25T09:49:40.000Z',
    updatedAt: '2021-10-25T09:49:40.000Z',
    shortlistCount: 0,
  },
  {
    id: 80169,
    name: 'Didn`t match with the professional skills',
    description:
      "We are sorry to inform you that your bid was rejected because you didn't match the project skills.",
    createdAt: '2021-10-25T09:49:40.000Z',
    updatedAt: '2021-10-25T09:49:40.000Z',
    shortlistCount: 1,
  },
  {
    id: 80170,
    name: 'The client has cancelled the project',
    description:
      'We are sorry to inform you that the client has closed the project on personal grounds.',
    createdAt: '2021-10-25T09:49:40.000Z',
    updatedAt: '2021-10-25T09:49:40.000Z',
    shortlistCount: 6,
  },
  {
    id: 80171,
    name: 'The client wasn`t satisfied with his rate',
    description: 'We are sorry to inform you that your rate is unacceptable for the client.',
    createdAt: '2021-10-25T09:49:40.000Z',
    updatedAt: '2021-10-25T09:49:40.000Z',
    shortlistCount: 0,
  },
  {
    id: 80172,
    name: 'Did not fit on location',
    description:
      "We are sorry to inform you that your bid was not accepted because you didn't match the location",
    createdAt: '2021-10-25T09:49:40.000Z',
    updatedAt: '2021-10-25T09:49:40.000Z',
    shortlistCount: 0,
  },
  {
    id: 80173,
    name: 'The client has selected another candidate',
    description: 'We regret to inform you that the client has selected another candidate.',
    createdAt: '2021-10-25T09:49:40.000Z',
    updatedAt: '2021-10-25T09:49:40.000Z',
    shortlistCount: 0,
  },
  {
    id: 80174,
    name: "Didn't match the project conditions",
    description:
      "We are sorry to inform you that your bid was not accepted because you didn't match the project conditions. Your possible workload or location is different from the required.",
    createdAt: '2021-10-25T09:49:40.000Z',
    updatedAt: '2021-10-25T09:49:40.000Z',
    shortlistCount: 0,
  },
  {
    id: 80175,
    name: 'The candidate refused',
    description: 'You have refused to participate in this project.',
    createdAt: '2021-10-25T09:49:40.000Z',
    updatedAt: '2021-10-25T09:49:40.000Z',
    shortlistCount: 0,
  },
  {
    id: 80176,
    name: 'Didn`t match with the soft skills',
    description:
      "We are sorry to inform you that your bid was rejected because you didn't match the project skills.",
    createdAt: '2021-10-25T09:49:40.000Z',
    updatedAt: '2021-10-25T09:49:40.000Z',
    shortlistCount: 0,
  },
  {
    id: 80177,
    name: 'Much more skills for this project',
    description:
      'We apologize for rejecting your application because you have more skills for this project.',
    createdAt: '2021-10-25T09:49:40.000Z',
    updatedAt: '2021-10-25T09:49:40.000Z',
    shortlistCount: 0,
  },
  {
    id: 80178,
    name: 'Was hired to another our vacancy',
    description: 'Was hired to another our vacancy',
    createdAt: '2021-10-25T09:49:40.000Z',
    updatedAt: '2021-10-25T09:49:40.000Z',
    shortlistCount: 0,
  },
  {
    id: 80179,
    name: 'Did not take place interview with HR department',
    description: 'Did not take place interview with HR department',
    createdAt: '2021-10-25T09:49:40.000Z',
    updatedAt: '2021-10-25T09:49:40.000Z',
    shortlistCount: 1,
  },
  {
    id: 80180,
    name: 'Did not finish testing/creative task',
    description: 'Did not finish testing/creative task',
    createdAt: '2021-10-25T09:49:40.000Z',
    updatedAt: '2021-10-25T09:49:40.000Z',
    shortlistCount: 0,
  },
  {
    id: 80181,
    name: 'Failed interview with customer',
    description: 'Failed interview with customer',
    createdAt: '2021-10-25T09:49:40.000Z',
    updatedAt: '2021-10-25T09:49:40.000Z',
    shortlistCount: 0,
  },
  {
    id: 80182,
    name: 'Security check failed',
    description: 'Security check failed',
    createdAt: '2021-10-25T09:49:40.000Z',
    updatedAt: '2021-10-25T09:49:40.000Z',
    shortlistCount: 0,
  },
  {
    id: 80183,
    name: 'The resume is not approved by the customer (High salary expectations, Not enough experience, No required soft skills, Another location)',
    description:
      'The resume is not approved by the customer (High salary expectations, Not enough experience, No required soft skills, Another location)',
    createdAt: '2021-10-25T09:49:40.000Z',
    updatedAt: '2021-10-25T09:49:40.000Z',
    shortlistCount: 0,
  },
  {
    id: 80184,
    name: "Didn't come to work place",
    description: "Didn't come to work place",
    createdAt: '2021-10-25T09:49:40.000Z',
    updatedAt: '2021-10-25T09:49:40.000Z',
    shortlistCount: 0,
  },
  {
    id: 80185,
    name: 'Failed telephone interview',
    description: 'Failed telephone interview',
    createdAt: '2021-10-25T09:49:40.000Z',
    updatedAt: '2021-10-25T09:49:40.000Z',
    shortlistCount: 0,
  },
  {
    id: 80186,
    name: 'Did not finish probation period',
    description: 'Did not finish probation period',
    createdAt: '2021-10-25T09:49:40.000Z',
    updatedAt: '2021-10-25T09:49:40.000Z',
    shortlistCount: 0,
  },
  {
    id: 80187,
    name: 'Fired',
    description: 'Fired',
    createdAt: '2021-10-25T09:49:40.000Z',
    updatedAt: '2021-10-25T09:49:40.000Z',
    shortlistCount: 0,
  },
  {
    id: 80188,
    name: 'Interview with customer was skipped',
    description: 'Interview with customer was skipped',
    createdAt: '2021-10-25T09:49:40.000Z',
    updatedAt: '2021-10-25T09:49:40.000Z',
    shortlistCount: 0,
  },
  {
    id: 80189,
    name: 'Resume declined',
    description: 'Resume declined',
    createdAt: '2021-10-25T09:49:40.000Z',
    updatedAt: '2021-10-25T09:49:40.000Z',
    shortlistCount: 0,
  },
];

export const mockedSpecialities = specialities;
export const mockedVacancyStatuses = [
  {
    id: 1,
    name: '- Not Published',
  },
  {
    id: 2,
    name: '- Hot',
  },
  {
    id: 3,
    name: 'Lost',
  },
  {
    id: 4,
    name: 'Deal done',
  },
  {
    id: 5,
    name: '- On Hold',
  },
  {
    id: 12,
    name: '- Sent to Reviewer',
  },
  {
    id: 13,
    name: '- Interview planned',
  },
  {
    id: 14,
    name: '- Waiting for a feedback',
  },
  {
    id: 15,
    name: '- Passive Search',
  },
];
export const mockedVacancyType = [
  {
    id: 2,
    name: 'Remote',
  },
  {
    id: 3,
    name: 'Full Time Office',
  },
  {
    id: 4,
    name: 'Paid Relocation',
  },
];
export const mockedShortListStatuses: DictionaryCandidateStage[] = [
  {
    id: 1959,
    name: 'Applies',
    code: 'applies',
    position: 0,
    color: '#fcbd2e',
    shortlistCount: 0,
    attachedFieldTypes: [],
  },
  {
    id: 2981,
    name: 'AI',
    code: 'ai',
    position: 1,
    color: '#fcbd2e',
    shortlistCount: 0,
    attachedFieldTypes: [],
  },
  {
    id: 1448,
    name: 'Long List',
    code: 'long_list',
    position: 2,
    color: '#fcbd2e',
    shortlistCount: 0,
    attachedFieldTypes: [],
  },
  {
    id: 15,
    name: 'View CV 10',
    code: 'custom',
    position: 3,
    color: '#5e89f2',
    shortlistCount: 0,
    attachedFieldTypes: [],
  },
  {
    id: 4913,
    name: 'йцуйцу',
    code: 'custom',
    position: 4,
    color: '#5e89f2',
    shortlistCount: 0,
    attachedFieldTypes: [],
  },
  {
    id: 4912,
    name: '123 Update',
    code: 'custom',
    position: 5,
    color: '#5e89f2',
    attachedFieldTypes: [{ id: 1, name: 'Custom field', type: 'text' }],
    shortlistCount: 0,
  },
  {
    id: 4911,
    name: 'Hi Update',
    code: 'custom',
    position: 6,
    color: '#5e89f2',
    shortlistCount: 0,
    attachedFieldTypes: [],
  },
  {
    id: 4224,
    name: 'wcbsc Stage',
    code: 'custom',
    position: 7,
    color: '#5e89f2',
    shortlistCount: 0,
    attachedFieldTypes: [],
  },
  {
    id: 4002,
    name: 'New',
    code: 'custom',
    position: 8,
    color: '#5e89f2',
    shortlistCount: 0,
    attachedFieldTypes: [],
  },
  {
    id: 395,
    name: 'Analyzing Profile',
    code: 'custom',
    position: 9,
    color: '#5e89f2',
    shortlistCount: 0,
    attachedFieldTypes: [],
  },
  {
    id: 365,
    name: 'Send TSP CV',
    code: 'custom',
    position: 10,
    color: '#5e89f2',
    shortlistCount: 0,
    attachedFieldTypes: [],
  },
  {
    id: 107,
    name: 'Job Offer',
    code: 'custom',
    position: 11,
    color: '#5e89f2',
    shortlistCount: 0,
    attachedFieldTypes: [],
  },
  {
    id: 100,
    name: 'Очікування рішення від клієнта',
    code: 'custom',
    position: 12,
    color: '#5e89f2',
    shortlistCount: 0,
    attachedFieldTypes: [],
  },
  {
    id: 93,
    name: 'Підготовка профіля',
    code: 'custom',
    position: 13,
    color: '#5e89f2',
    shortlistCount: 0,
    attachedFieldTypes: [],
  },
  {
    id: 91,
    name: 'dfsdfd',
    code: 'custom',
    position: 14,
    color: '#5e89f2',
    shortlistCount: 0,
    attachedFieldTypes: [],
  },
  {
    id: 20,
    name: 'New Status 2',
    code: 'custom',
    position: 15,
    color: '#5e89f2',
    shortlistCount: 0,
    attachedFieldTypes: [],
  },
  {
    id: 19,
    name: 'New Status 1',
    code: 'custom',
    position: 16,
    color: '#5e89f2',
    shortlistCount: 0,
    attachedFieldTypes: [],
  },
  {
    id: 18,
    name: 'Sent mes in LI',
    code: 'custom',
    position: 17,
    color: '#5e89f2',
    shortlistCount: 0,
    attachedFieldTypes: [],
  },
  {
    id: 17,
    name: 'Contact to Skype',
    code: 'custom',
    position: 18,
    color: '#5e89f2',
    shortlistCount: 0,
    attachedFieldTypes: [],
  },
  {
    id: 14,
    name: 'Contact to LinkedIn',
    code: 'custom',
    position: 19,
    color: '#5e89f2',
    shortlistCount: 0,
    attachedFieldTypes: [],
  },
  {
    id: 426,
    name: 'Sent to Reviewer',
    code: 'sent_to_review',
    position: 20,
    color: '#5e89f2',
    shortlistCount: 0,
    attachedFieldTypes: [],
  },
  {
    attachedFieldTypes: [{ name: '_interview', id: 123, type: 'text' }],
    id: 937,
    name: 'Interview Planned',
    code: 'interview_planned',
    position: 21,
    color: '#5e89f2',
    shortlistCount: 0,
  },
  {
    id: 3492,
    name: 'Hired',
    code: 'hired',
    position: 22,
    color: '#3bc46a',
    shortlistCount: 0,
    attachedFieldTypes: [],
  },
  {
    id: 2470,
    name: 'Not accepted',
    code: 'not_accepted',
    position: 23,
    color: '#e42f4d',
    shortlistCount: 0,
    attachedFieldTypes: [],
  },
  {
    attachedFieldTypes: [{ name: '_k2_protected', id: 123, type: 'text' }],
    id: 2777,
    name: 'Send to external system',
    code: 'custom',
    position: 24,
    color: '#5e89f2',
    shortlistCount: 0,
  },
];
export const mockedShortListSources = [
  { id: 23, name: 'AI', code: 'ai' },
  { id: 32, name: 'Amazing Huring', code: 'amazing_huring' },
  { id: 34, name: 'Angel.co', code: 'angel_co' },
  { id: 39, name: 'Applies', code: 'applies' },
  { id: 31, name: 'Ask Ubuntu', code: 'ask_ubuntu' },
  { id: 14, name: 'CV Parsing', code: 'cv_parsing' },
  { id: 41, name: 'Dice', code: 'dice' },
  { id: 28, name: 'Djinni', code: 'djinni' },
  { id: 27, name: 'DOU', code: 'dou' },
  { id: 33, name: 'Dribbble', code: 'dribbble' },
  { id: 22, name: 'Elance', code: 'elance' },
  { id: 19, name: 'Extension', code: 'extension' },
  { id: 24, name: 'Facebook', code: 'facebook' },
  { id: 2, name: 'Github', code: 'github' },
  { id: 3, name: 'Github SC', code: 'github_lite' },
  { id: 35, name: 'GitLab', code: 'gitlab' },
  { id: 42, name: 'Gmail', code: 'gmail' },
  { id: 46, name: 'grc.ua', code: 'grc' },
  { id: 25, name: 'Green House', code: 'green_house' },
  { id: 20, name: 'HeadHunter', code: 'head_hunter' },
  { id: 17, name: 'Import', code: 'import' },
  { id: 43, name: 'Indeed', code: 'indeed' },
  { id: 1, name: 'LinkedIn', code: 'linkedin' },
  { id: 12, name: 'LinkedIn Google', code: 'linkedin_google' },
  { id: 9, name: 'Linkedin SC', code: 'linkedin_lite' },
  { id: 13, name: 'Manually', code: 'manually' },
  { id: 40, name: 'Manually', code: 'undefined' },
  { id: 16, name: 'Merge Talents', code: 'merge_talents' },
  { id: 30, name: 'Moi Krug', code: 'moi_krug' },
  { id: 44, name: 'Monster', code: 'monster' },
  { id: 4, name: 'My Talents', code: 'my_talents' },
  { id: 45, name: 'Other', code: 'other' },
  { id: 11, name: 'Personal Assistant', code: 'personal_assistant' },
  { id: 10, name: 'Profile Page', code: 'profile_page' },
  { id: 15, name: 'Project Source', code: 'project_source' },
  { id: 5, name: 'Public Talents', code: 'public_talents' },
  { id: 7, name: 'Rabota.ua', code: 'rabota_ua' },
  { id: 21, name: 'Sendgrid', code: 'sendgrid' },
  { id: 6, name: 'Stack Overflow', code: 'stackoverflow' },
  { id: 38, name: 'Stack Overflow SC', code: 'stackoverflow_lite' },
  { id: 18, name: 'TurboHiring', code: 'turbohiring' },
  { id: 36, name: 'Twitter', code: 'twitter' },
  { id: 29, name: 'Upwork', code: 'upwork' },
  { id: 37, name: 'vk.com', code: 'vk_com' },
  { id: 8, name: 'Work.ua', code: 'work_ua' },
  { id: 26, name: 'Xing', code: 'xing' },
];
export const mockedShortListBoards: DictionaryShortListBoard[] = [
  { projectsCount: 0, id: 332, name: 'Alex2Alex' },
  { projectsCount: 0, id: 333, name: 'Alex2Alex Board' },
  { projectsCount: 1719, id: 2, name: 'Default board' },
];
export const mockedDefaultShortListBoard: DictionaryShortListBoard = {
  projectsCount: 1503,
  id: 2,
  name: 'Default board',
  columns: [
    {
      id: 8,
      position: 1,
      status: { id: 1448, name: 'Long List' },
      color: '#d6cbc5',
    },
  ],
  createdAt: '2019-06-12T12:23:54+03:00',
};
export const mockedTags: DictionaryItem[] = tags;

export const mockedVacancyFields = [
  {
    id: 8,
    name: 'VacancyStart',
    type: 'date',
    showInPanel: false,
  },
  {
    id: 9,
    name: 'VacancyClient',
    type: 'text',
    showInPanel: false,
  },
  {
    id: 13,
    name: 'VacancyBudget',
    type: 'int',
    showInPanel: false,
  },
  {
    id: 14,
    name: 'VacanyRemote',
    type: 'bool',
    showInPanel: false,
  },
  {
    id: 49,
    name: 'VacancyPost',
    type: 'date',
    showInPanel: true,
  },
  {
    id: 57,
    name: 'customField',
    type: 'date',
    showInPanel: false,
  },
  {
    id: 61,
    name: 'Responsible Person',
    type: 'text',
    showInPanel: true,
  },
];

export const mockedEmailTemplates = [
  {
    id: 2,
    name: 'Custom template notifications',
    body: '<p>Hi {{first_name}},</p><br>          \r\n<p>I would like to invite you to our open vacancy "{{vacancy_name}}"<br>          You can apply by this URL {{vacancy_url}}<br>          \r\n{{vacancy_description}}<br>           <br>          \r\nBest regards, <br>          \r\n{{recruiter_first_name}}</p>',
    header: 'header',
    footer: 'Footer',
    type: 'notification',
  },
  {
    id: 1039,
    name: 'AOG Notification',
    body: 'body',
    header: 'header',
    footer: 'footer',
    type: 'notification',
  },
  {
    id: 1570,
    name: 'Default Template for export data',
    body: 'Hey</br>We are happy to inform you that your request for upload {{export_type}} data has been completed Here is a link to download them.</br>Kind Regards,{{recruiter_first_name}}',
    subject: 'Your request for upload {{export_type}} data has been completed',
    type: 'export',
  },
  {
    id: 4086,
    name: 'Name',
    body: '<p>Body</p>',
    header: 'Header',
    footer: 'Footer',
    type: 'notification',
  },
];
