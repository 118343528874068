export interface DashboardData {
  candidates: DashboardCandidatesData;
  notifications: DashboardInvitationsData;
  profiles: DashboardProfilesData;
  vacancies: DashboardVacanciesData;
}

export interface DashboardCount {
  count: number;
}

export interface DashboardCandidatesCounts extends DashboardCount {
  appliedCount: number;
  appliedNotProcessedCount: number;
  avgApplyTime: number;
  researchedCount: number;
}

export interface DashboardFilesCounts extends DashboardCount {
  cv_count: number;
  vr_count: number;
}

export interface DashboardLettersCounts {
  bounced: number;
  clicked: number;
  letters: number;
  notifications: number;
  opened: number;
  spam: number;
  unsubscribed: number;
}
export interface DashboardVacanciesCount extends DashboardCount {
  avgProcessTime: number;
  hiredCount: number;
  interviewedCount: number;
  reviewedCount: number;
}

export interface DashboardRecruiter {
  recruiterId: number;
  recruiterName: string;
}

export interface DashboardCandidatesData {
  total: DashboardCandidatesCounts;
  items: (DashboardCandidatesCounts & DashboardRecruiter)[];
}

export interface DashboardInvitationsData {
  total: DashboardLettersCounts;
  items: (DashboardLettersCounts & DashboardRecruiter)[];
}

export interface DashboardProfilesData {
  total: DashboardFilesCounts;
  items: (DashboardFilesCounts & DashboardRecruiter)[];
}

export interface DashboardVacanciesData {
  total: DashboardVacanciesCount;
  items: (DashboardVacanciesCount & DashboardRecruiter)[];
}

export type DashboardSubscriptionStatus = 'active' | 'trial' | 'expired';

export interface DashboardPortalAccountsData {
  portalId: number;
  portalName: string;
  totalRecruiters: number;
  activeRecruiters: number;
  stripeLimit: number;
  subscriptionEndDate: Date;
  subscriptionStatus: DashboardSubscriptionStatus;
  portalAdmin: string;
  lastPayment: number;
  totalPayment: number;
}

export interface DashboardPortalStatistic {
  accounts: DashboardPortalAccountsData[];
}
