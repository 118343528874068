import { UserSubscription } from '@app-shared/models';
import { createAction, props, union } from '@ngrx/store';

const ActivateTeamTrialPeriodAction = createAction(
  '[Stripe Subscription Dialog] Activate team trial period',
);
const ActivateTeamTrialPeriodFailedAction = createAction(
  '[Stripe Subscription Dialog] Error happened during activating team trial period',
);
const ActivateTeamTrialPeriodSuccessAction = createAction(
  '[Stripe Subscription Dialog] Team trial period was successfully activated',
  props<{ subscription: UserSubscription }>(),
);
const ActivateTrialPeriodAction = createAction(
  '[Stripe Subscription Dialog] Activate trial period',
);
const ActivateTrialPeriodFailedAction = createAction(
  '[Stripe Subscription Dialog] Error happened during activating trial period',
);
const ActivateTrialPeriodSuccessAction = createAction(
  '[Stripe Subscription Dialog] Trial period was successfully activated',
  props<{ subscription: UserSubscription }>(),
);
const OpenConfirmationDialogForTeamTrialAction = createAction(
  '[Stripe Subscription Dialog] Open confirmation dialog for switching to team trial',
);
const OpenSubscriptionPlansDialogAction = createAction(
  '[Stripe Subscription Dialog] Open popup for select subscription plan',
);

const actions = {
  ActivateTeamTrialPeriodAction,
  ActivateTeamTrialPeriodFailedAction,
  ActivateTeamTrialPeriodSuccessAction,
  ActivateTrialPeriodAction,
  ActivateTrialPeriodFailedAction,
  ActivateTrialPeriodSuccessAction,
  OpenConfirmationDialogForTeamTrialAction,
  OpenSubscriptionPlansDialogAction,
};

const stripeSubscriptionActions = union(actions);

export type StripeSubscriptionActionsType = typeof stripeSubscriptionActions;

export const StripeSubscriptionActions = actions;
