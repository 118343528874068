import {
  DialogPayload,
  ExportEntitiesPayload,
  PaginationParams,
  SearchParams,
  SortParams,
  VacanciesListQuickFilters,
  VacancySimpleAssignedRecruiterUpdatePayload,
  VacancySimpleStatusUpdatePayload,
  VacancySimpleVisibilityUpdatePayload,
} from '@app-shared/models';
import { createAction, props, union } from '@ngrx/store';

const ChangeRecruiterAction = createAction(
  '[Vacancy Preview Card] Change assigned recruiter',
  props<{ data: VacancySimpleAssignedRecruiterUpdatePayload; target: 'assigned' }>(),
);
const ChangeVacancySimpleCardViewState = createAction(
  '[Vacancy Preview Card] Change vacancy simple card view',
  props<{ viewState: boolean }>(),
);
const ChangeStatusAction = createAction(
  '[Vacancy Preview Card] Change vacancy status',
  props<{ data: VacancySimpleStatusUpdatePayload; target: 'status' }>(),
);
const CleanSearchParamsAction = createAction('[Vacancies List] Clean form and search params');
const DeleteVacancyFromPreviewAction = createAction(
  '[Vacancies List] Try to delete vacancy',
  props<{ id: number }>(),
);
const DownloadVacanciesListInExcelAction = createAction(
  '[Vacancies List] Download vacancies list in Excel format',
  props<{ payload: ExportEntitiesPayload }>(),
);
const DownloadCandidatesListInExcelAction = createAction(
  '[Vacancies List] Download candidates list in Excel format',
);
const NavigateToProfilesFromVacancyAction = createAction(
  '[Vacancy Preview Card] Navigate to profiles from vacancies',
  props<{ id: number; searchParams: SearchParams }>(),
);
const OpenVacancyDetailsSidepanelAction = createAction(
  '[Vacancies List] Open sidepanel with vacancies details',
  props<{ vacancyId: number; target: string }>(),
);
const OpenExportVacanciesDialogAction = createAction(
  '[Vacancies List] Open dialog for exporting vacancies',
  props<{ payload: DialogPayload }>(),
);
const SaveQuickFilterAction = createAction(
  '[Vacancies List] Save quick filter selection to recruiter profile',
  props<{ filter: VacanciesListQuickFilters }>(),
);
const ShowInPublicJobBoardAction = createAction(
  '[Vacancy Preview Card] Show vacancy on public site',
  props<{ data: VacancySimpleVisibilityUpdatePayload; target: 'visibility' }>(),
);
const UpdatePaginationParamsAction = createAction(
  '[Vacancies List Pagination] Update pagination parameters',
  props<{ params: PaginationParams }>(),
);
const UpdateSearchParamsAction = createAction(
  '[Vacancies List Filters] Update search parameters',
  props<{ params: SearchParams }>(),
);
const UpdateSortParamsAction = createAction(
  '[Vacancies List Control Bar] Update sorting parameters',
  props<{ params: SortParams }>(),
);

const actions = {
  ChangeRecruiterAction,
  ChangeVacancySimpleCardViewState,
  ChangeStatusAction,
  CleanSearchParamsAction,
  DeleteVacancyFromPreviewAction,
  DownloadVacanciesListInExcelAction,
  DownloadCandidatesListInExcelAction,
  NavigateToProfilesFromVacancyAction,
  OpenVacancyDetailsSidepanelAction,
  OpenExportVacanciesDialogAction,
  SaveQuickFilterAction,
  ShowInPublicJobBoardAction,
  UpdatePaginationParamsAction,
  UpdateSearchParamsAction,
  UpdateSortParamsAction,
};

const vacanciesListUserActions = union(actions);

export type VacanciesListUserActionsType = typeof vacanciesListUserActions;

export const VacanciesListUserActions = actions;
