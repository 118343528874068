import { DictionariesEnum } from '@app-shared/models';

export const vacanciesListDictionaries = [
  DictionariesEnum.clients,
  DictionariesEnum.typeDate,
  DictionariesEnum.vacancyStatuses,
  DictionariesEnum.vacancyType,
  DictionariesEnum.experiences,
];

export const vacancyProfileDictionaries = [
  DictionariesEnum.cities,
  DictionariesEnum.clients,
  DictionariesEnum.countries,
  DictionariesEnum.durations,
  DictionariesEnum.employments,
  DictionariesEnum.englishLevels,
  DictionariesEnum.experiences,
  DictionariesEnum.mainSkills,
  DictionariesEnum.skills,
  DictionariesEnum.specialities,
  DictionariesEnum.tags,
  DictionariesEnum.vacancyStatuses,
  DictionariesEnum.vacancyType,
];

export const vacancyStatisticLabels = {
  plugin_view: {
    facebook: 'Facebook',
    github: 'GitHub',
    linkedin: 'LinkedIn',
    rabota_ua: 'Rabota.ua',
    stackoverflow: 'StackOverflow',
    work_ua: 'Work.ua',
    xing: 'Xing',
  },
  view_profiles_in_search: {
    githubSmart: 'GitHub',
    linkedinSmart: 'LinkedIn',
    profiles: 'Public Profiles',
    stackoverflowSmart: 'StackOverflow',
    candidates: 'My Profiles',
    turbohiring: 'TurboHiring',
  },
};

export const vacancyListSortOptions = [
  {
    value: { sortField: 'createdAt', sortDirection: 'desc' },
    name: 'vacancies-list.sort-options.created-desc',
  },
  {
    value: { sortField: 'createdAt', sortDirection: 'asc' },
    name: 'vacancies-list.sort-options.created-asc',
  },
  {
    value: { sortField: 'status', sortDirection: 'asc' },
    name: 'vacancies-list.sort-options.status-asc',
  },
  {
    value: { sortField: 'status', sortDirection: 'desc' },
    name: 'vacancies-list.sort-options.status-desc',
  },
  {
    value: { sortField: 'name', sortDirection: 'asc' },
    name: 'vacancies-list.sort-options.name-asc',
  },
  {
    value: { sortField: 'name', sortDirection: 'desc' },
    name: 'vacancies-list.sort-options.name-desc',
  },
  {
    value: { sortField: 'recruiters', sortDirection: 'asc' },
    name: 'vacancies-list.sort-options.recruiters-asc',
  },
  {
    value: { sortField: 'recruiters', sortDirection: 'desc' },
    name: 'vacancies-list.sort-options.recruiters-desc',
  },
];
