import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { appVersionToken, AppVersionData } from '@app-shared/models/configuration.model';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  void fetch('./assets/settings/version.json', { cache: 'no-store' })
    .then((versionResponse) => versionResponse.json())
    .then((version: AppVersionData) =>
      platformBrowserDynamic([{ provide: appVersionToken, useValue: version }])
        .bootstrapModule(AppModule)
        // eslint-disable-next-line no-console
        .catch((err) => console.log(err)),
    );
});
