/* eslint-disable @typescript-eslint/member-ordering */
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  __,
  always,
  concat,
  drop,
  find,
  forEach,
  head,
  ifElse,
  includes,
  isEmpty,
  isNil,
  mergeAll,
  pathOr,
  pipe,
  prop,
  propEq,
  propOr,
  map as ramdaMap,
  reject,
  split,
  test,
  unless,
  update,
} from 'ramda';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';

import { environment } from '@app-environment/environment';
import { ProfilesSources, SearchParams } from '@app-shared/models';
import { State } from '@app-shared/reducers';
import { HttpHelperService } from '@app-shared/services/http-helper/http-helper.service';

import {
  getPublicVacancyUrl,
  getUserLocalization,
  portalSupportUrl,
} from '@app-core/root/reducer/root.reducer';
import { RouterActions } from '@app-shared/actions/router.actions';
import { ScriptsActions } from '@app-shared/actions/scripts-injection.actions';
import { ProfileModificationActions } from '@app-shared/effects/profile-modification/profile-modification.actions';

import { ReloadPageAfterChangeSpaceSuccessAction } from '@app-core/auth/actions/auth.system.actions';
import { AuthUserActions } from '@app-core/auth/actions/auth.user.actions';

import { ProfileSidepanelUserActions } from '@app-core/profile-side-panel/actions/profile-sidepanel.user.actions';
import { NavigateToNewQueryParamsAction } from '@app-core/search-console/actions/search-console.system.actions';
import { SearchConsoleUserActions } from '@app-core/search-console/actions/search-console.user.actions';
import { VacancyProfilesSystemActions } from '@app-core/vacancy-profiles/actions/vacancy-profiles.system.actions';

import { AddNewEntityUserActions } from '@app-core/add-new-entity-popup/actions/new-entity.user.actions';
import { getShortDeveloperInfo } from '@app-core/profile-side-panel/reducers/profile-sidepanel.reducer';
import { RootSystemActions } from '@app-core/root/actions/root.system.actions';
import { RootUserActions } from '@app-core/root/actions/root.user.actions';
import {
  getExternalSearchSource,
  getPaginationState,
  getSearchParameters,
  getSortingState,
  getSource,
} from '@app-core/search-console/reducers/search-console.reducer';
import { StartDashboardActions } from '@app-core/start-dashboard/actions/start-dashboard.actions';
import { StripeSubscriptionActions } from '@app-core/stripe-subscription/actions/stripe-subscription.actions';
import { VacanciesListUserActions } from '@app-core/vacancies-list/actions/vacancies-list.user.actions';
import {
  getPaginationState as getPaginationVacancyState,
  getSearchParameters as getSearchVacancyParameters,
  getSortingState as getSortingVacancyState,
} from '@app-core/vacancy-profiles/reducer/vacancy-profiles.reducer';
import { VacancySidepanelUserActions } from '@app-core/vacancy-side-panel/actions/vacancy-sidepanel.user.actions';
import { getFullVacancyInfo } from '@app-core/vacancy-side-panel/reducers/vacancy-sidepanel.reducer';

@Injectable()
export class RouterEffects {
  public pluginId = environment.chromePluginId;
  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly store$: Store<State>,
  ) {}

  public cleanPopupNavigation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          RouterActions.CleanExtraPopupNavigationAction,
          RouterActions.CleanExtraSidepanelNavigationAction,
          VacancySidepanelUserActions.ClosePanelAction,
        ),
        tap(() => {
          void this.router.navigate([`/`, { outlets: { extras: null } }], {
            queryParams: { id: null },
            queryParamsHandling: 'merge',
          });
        }),
      ),
    { dispatch: false },
  );

  public logOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RootUserActions.LogOutAction),
        tap(() => {
          void this.router.navigate(['/', 'logout']);
        }),
      ),
    { dispatch: false },
  );

  public navigateToInternalOrExternalUrl$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          RouterActions.NavigateAfterLoginAction,
          StartDashboardActions.NavigateToFunctionalityAction,
        ),
        withLatestFrom(this.store$),
        map(([{ openInNewPage, path }, state]) => ({
          openInNewPage,
          path,
          locale: getUserLocalization(state),
        })),
        tap(({ openInNewPage, path, locale }) => {
          if (test(/^http/g, path)) {
            if (openInNewPage) {
              window.open(path, '_blank');
              return;
            }
            this.document.location.href = path;
          } else {
            const localeFromPath = pipe(split('/'), reject(isEmpty), head)(path);
            const pathWithLocale = includes(localeFromPath, ['en', 'ru', 'uk'])
              ? path
              : `${locale}/${path}`;
            void this.router.navigateByUrl(pathWithLocale);
          }
        }),
      ),
    { dispatch: false },
  );

  public navigateToClientsList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.NavigateToClientsListAction),
        withLatestFrom(this.store$),
        map(([{ params }, state]) => ({ params, locale: getUserLocalization(state) })),
        tap(({ params, locale }) => {
          void this.router.navigate(
            [
              {
                outlets: {
                  primary: [locale, 'clients'],
                  extras: null,
                },
              },
            ],
            params,
          );
        }),
      ),
    { dispatch: false },
  );

  public navigateToDashboardPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.NavigateToDashboardPageAction),
        withLatestFrom(this.store$),
        map(([{ params }, state]) => ({ params, locale: getUserLocalization(state) })),
        tap(({ params, locale }) => {
          void this.router.navigate(
            [
              {
                outlets: {
                  primary: [locale, 'dashboard'],
                  extras: null,
                },
              },
            ],
            {
              queryParams: params,
            },
          );
        }),
      ),
    { dispatch: false },
  );

  public navigateToExternalUrl$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.NavigateToExternalUrlAction),
        tap(({ path }) => {
          window.open(path, Math.random().toString());
        }),
      ),
    { dispatch: false },
  );

  public navigateToNotificationTemplateCreation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.NavigateToNotificationTemplateCreationAction),
        withLatestFrom(this.store$),
        map(([, state]) => getUserLocalization(state)),
        tap((locale) => {
          void this.router.navigate([
            {
              outlets: {
                primary: [locale, 'directories', 'notification-templates'],
                extras: ['popup', 'add-new', 'directory'],
              },
            },
          ]);
        }),
      ),
    { dispatch: false },
  );

  public navigateToProfilePage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          RouterActions.NavigateToProfilePageAction,
          ProfileModificationActions.MergeProfileDuplicatesSuccessAction,
        ),
        withLatestFrom(this.store$),
        map(([{ id }, state]) => ({ id, locale: getUserLocalization(state) })),
        tap(({ id, locale }) => {
          void this.router.navigate([
            { outlets: { primary: [locale, 'profile', id, 'details'], extras: null } },
          ]);
        }),
      ),
    { dispatch: false },
  );

  public navigateToPublicVacancyPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.NavigateToPublicVacancyPageAction),
        withLatestFrom(this.store$),
        map(([{ vacancySlug }, state]) => ({
          vacancySlug,
          newTab: window.open('', '_blank'),
          locale: getUserLocalization(state),
          publicVacancyUrl: getPublicVacancyUrl(state),
        })),
        tap(({ publicVacancyUrl, locale, newTab, vacancySlug }) => {
          newTab.document.location.href = `${publicVacancyUrl}/${locale}/${vacancySlug}`;
        }),
      ),
    { dispatch: false },
  );

  public navigateToRootAfterChangeSpace$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReloadPageAfterChangeSpaceSuccessAction),
        tap(() => {
          void this.router.navigate(['/', 'en', 'redirect']);
        }),
      ),
    { dispatch: false },
  );

  public navigateToStartAfterActivatingTrial$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          StripeSubscriptionActions.ActivateTeamTrialPeriodSuccessAction,
          StripeSubscriptionActions.ActivateTrialPeriodSuccessAction,
        ),
        withLatestFrom(this.store$),
        map(([, state]) => getUserLocalization(state)),
        tap((locale) => {
          void this.router.navigate([{ outlets: { primary: [locale, 'start'], extras: null } }]);
        }),
      ),
    { dispatch: false },
  );

  public navigateToSearchConsole$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.NavigateToSearchConsoleAction),
        withLatestFrom(this.store$),
        map(([{ params }, state]) => ({ params, locale: getUserLocalization(state) })),
        tap(({ params, locale }) => {
          void this.router.navigate(
            [
              {
                outlets: {
                  primary: [locale, 'profiles'],
                  extras: null,
                },
              },
            ],
            { queryParams: HttpHelperService.serializeNestedParams(params) },
          );
        }),
      ),
    { dispatch: false },
  );

  public navigateToSupportPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.NavigateToSupportPage),
        withLatestFrom(this.store$),
        map(([, state]) => portalSupportUrl(state)),
        tap((url) => {
          window.open(url, Math.random().toString());
        }),
      ),
    { dispatch: false },
  );

  public navigateToVacanciesList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.NavigateToVacanciesListAction),
        withLatestFrom(this.store$),
        map(([{ params }, state]) => ({ params, locale: getUserLocalization(state) })),
        tap(({ params, locale }) => {
          void this.router.navigate(
            [
              {
                outlets: {
                  primary: [locale, 'vacancies'],
                  extras: null,
                },
              },
            ],
            params,
          );
        }),
      ),
    { dispatch: false },
  );

  public navigateToVacancyProfiles$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.NavigateToVacancyProfilesAction),
        withLatestFrom(this.store$),
        map(([{ path, queryParams = {} }, state]) => ({
          path,
          locale: getUserLocalization(state),
          queryParams,
        })),
        tap(({ path, locale, queryParams }) => {
          void this.router.navigate(
            [
              {
                outlets: {
                  primary: [locale, ...path],
                  extras: null,
                },
              },
            ],
            { queryParams },
          );
        }),
      ),
    { dispatch: false },
  );

  public openCreatePopup$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          RouterActions.OpenCreatePopupAction,
          RouterActions.OpenEditPopupAction,
          RouterActions.OpenDuplicatePopupAction,
        ),
        tap(({ path }) => {
          void this.router.navigate(['/', { outlets: { extras: path } }], {
            queryParamsHandling: 'merge',
          });
        }),
      ),
    { dispatch: false },
  );

  public openStripeSubscriptionPopup$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(StripeSubscriptionActions.OpenSubscriptionPlansDialogAction),
        map(() =>
          this.router.navigate(['/', { outlets: { extras: ['popup', 'pricing'] } }], {
            queryParamsHandling: 'merge',
          }),
        ),
      ),
    { dispatch: false },
  );

  public openLinkedinAndSendMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RootUserActions.SendLinkedinNotificationsAction),
        map(({ template, contacts }) => {
          unless(
            isEmpty,
            pipe(
              ramdaMap((contact: string): string => `${contact}?openMessage=true`),
              forEach((link: string) => {
                if (link && 'chrome' in window && chrome.runtime) {
                  window.open(link, '_blank');
                  void chrome.runtime.sendMessage(this.pluginId, { template });
                }
              }),
            ),
          )(contacts);
        }),
      ),
    { dispatch: false },
  );

  public openLoginPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthUserActions.OpenLoginPageAction),
        tap(({ email }) => {
          void this.router.navigate(['/en/login'], { queryParams: { email } });
        }),
      ),
    { dispatch: false },
  );

  public openProfileSocialLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RootSystemActions.GetProfileContactsSuccessAction),
      filter(({ shouldOpenPopup }) => !shouldOpenPopup),
      withLatestFrom(this.store$),
      map(([{ data, clickedContact }, state]) => {
        const queryParams = getSearchParameters(state);
        const toCopy = includes(clickedContact, ['email', 'skype', 'phone']);

        const selectedContact = pipe(
          prop('contacts'),
          ifElse(
            always(toCopy),
            pathOr(null, ['mainContacts', `${clickedContact}s`, 0, 'value']),
            pipe(
              prop('socialLinks'),
              find(propEq(clickedContact, 'type')),
              unless(isNil, pipe(prop('url'), concat(__, `?${JSON.stringify(queryParams)}`))),
            ),
          ),
        )(data);

        return toCopy
          ? ScriptsActions.CopyTextToClipboard({ value: selectedContact })
          : RouterActions.NavigateToExternalUrlAction({
              path: selectedContact,
            });
      }),
    ),
  );

  public openProfileSidepanel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.OpenProfileDetailsSidepanelAction),
        tap(({ devId, target }) => {
          void this.router.navigate(
            ['./', { outlets: { extras: ['profile', 'details', devId, ...target] } }],
            {
              queryParamsHandling: 'merge',
            },
          );
        }),
      ),
    { dispatch: false },
  );

  public openTrello$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.OpenTrelloAction),
        tap(({ name, url }) => {
          const fullUrl = `${environment.angularSiteUrl}/${url}`;
          const path = `https://trello.com/en-GB/add-card?name=${name}&url=${fullUrl}`;
          window.open(path, '_blank');
        }),
      ),
    { dispatch: false },
  );

  public openVacancySidepanel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VacanciesListUserActions.OpenVacancyDetailsSidepanelAction),
        tap(({ vacancyId, target }) => {
          void this.router.navigate(
            ['/', { outlets: { extras: ['vacancy', 'details', vacancyId, target] } }],
            {
              queryParamsHandling: 'merge',
            },
          );
        }),
      ),
    { dispatch: false },
  );

  public switchProfilesModule$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SearchConsoleUserActions.NavigateToProfilesModule),
        withLatestFrom(this.store$),
        map(([{ source }, state]) => ({
          source,
          params: mergeAll([getSearchParameters(state), getSortingState(state)]),
          locale: getUserLocalization(state),
        })),
        tap(({ source, params, locale }) => {
          const queryParams = HttpHelperService.serializeNestedParams({
            queryParams: params,
          });
          void this.router.navigate([locale, source], { queryParams });
        }),
      ),
    { dispatch: false },
  );

  public updateQueryParamsOnSearchConsole$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NavigateToNewQueryParamsAction),
        withLatestFrom(this.store$),
        map(([, state]) => ({
          requestedModule: getSource(state),
          params: mergeAll([
            getSearchParameters(state),
            getSortingState(state),
            getPaginationState(state),
          ]),
          externalSearchSource: getExternalSearchSource(state),
          locale: getUserLocalization(state),
        })),
        tap(({ requestedModule, params, locale, externalSearchSource }) => {
          const searchParams =
            externalSearchSource && requestedModule === ProfilesSources.EXTERNAL
              ? { ...params, externalSearchSource }
              : params;
          const queryParams = HttpHelperService.serializeNestedParams({
            queryParams: searchParams,
          });
          void this.router.navigate([locale, requestedModule], { queryParams });
        }),
      ),
    { dispatch: false },
  );

  public updateQueryParamsOnCandidatesPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VacancyProfilesSystemActions.NavigateToNewQueryParamsAction),
        withLatestFrom(this.store$),
        map(([, state]) => ({
          params: mergeAll([
            getPaginationVacancyState(state),
            getSearchVacancyParameters(state),
            getSortingVacancyState(state),
          ]) as SearchParams,
          locale: getUserLocalization(state),
        })),
        tap(({ params, locale }) => {
          const queryParams = HttpHelperService.serializeNestedParams({
            queryParams: params,
          });
          const navigateParams = pipe(
            split('?'),
            head,
            split('/'),
            reject(isEmpty),
            drop(1),
          )(this.router.url);
          void this.router.navigate([locale, ...navigateParams], { queryParams });
        }),
      ),
    { dispatch: false },
  );

  public updateActivitiesTabOnProfileSidePanel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileSidepanelUserActions.ChangeActivitiesTabAction),
        withLatestFrom(this.store$),
        map(([{ tab }, state]) => ({
          tab,
          profile: getShortDeveloperInfo(state),
        })),
        tap(({ tab, profile }) => {
          void this.router.navigate(
            [
              {
                outlets: {
                  extras: ['profile', 'details', profile.id, 'activity', tab],
                },
              },
            ],
            { queryParamsHandling: 'merge' },
          );
        }),
      ),
    { dispatch: false },
  );

  public updateEditTalentDialogTab$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AddNewEntityUserActions.ChangeEditTalentDialogTabAction),
        tap(({ template, devId, isEditing }) => {
          const path = isEditing
            ? ['popup', 'edit', devId, 'talent', template]
            : ['popup', 'add-new', 'talent', template];
          void this.router.navigate(['/', { outlets: { extras: path } }], {
            queryParamsHandling: 'merge',
          });
        }),
      ),
    { dispatch: false },
  );

  public updatePageOnLocalizationChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AuthUserActions.ChangeLocalizationAction,
          RootSystemActions.ChangeLocalizationSuccessAction,
        ),
        tap(({ locale }) => {
          const queryParams = pathOr({}, ['currentUrlTree', 'queryParams'], this.router);
          const navigateParams = pipe(
            propOr('', 'url'),
            split('?'),
            head,
            split('/'),
            reject(isEmpty),
            update(0, locale as string),
          )(this.router);
          void this.router.navigate(navigateParams, { queryParams });
        }),
      ),
    { dispatch: false },
  );

  public updateRelatedTabOnProfileSidePanel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileSidepanelUserActions.ChangeRelatedTabAction),
        withLatestFrom(this.store$),
        map(([{ tab }, state]) => ({
          tab,
          profile: getShortDeveloperInfo(state),
        })),
        tap(({ tab, profile }) => {
          void this.router.navigate(
            [
              {
                outlets: {
                  extras: ['profile', 'details', profile.id, 'related', tab],
                },
              },
            ],
            { queryParamsHandling: 'merge' },
          );
        }),
      ),
    { dispatch: false },
  );

  public updateTabOnProfileSidePanel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileSidepanelUserActions.ChangeTabAction),
        withLatestFrom(this.store$),
        map(([{ tab }, state]) => ({
          tab,
          profile: getShortDeveloperInfo(state),
        })),
        tap(({ tab, profile }) => {
          void this.router.navigate(
            [
              {
                outlets: {
                  extras: ['profile', 'details', profile.id, tab],
                },
              },
            ],
            { queryParamsHandling: 'merge' },
          );
        }),
      ),
    { dispatch: false },
  );

  public updateTabOnVacancySidePanel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VacancySidepanelUserActions.ChangeTabAction),
        withLatestFrom(this.store$),
        map(([{ tab, id }, state]) => ({
          tab,
          id,
          vacancy: getFullVacancyInfo(state),
        })),
        tap(({ tab, vacancy, id }) => {
          const pathEnding = tab === 'candidates' ? [tab, id] : [tab];
          void this.router.navigate(
            [
              {
                outlets: {
                  extras: ['vacancy', 'details', vacancy.id, ...pathEnding],
                },
              },
            ],
            { queryParamsHandling: 'merge' },
          );
        }),
      ),
    { dispatch: false },
  );
}
